import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Menu } from '../../models/menu';
import { Router } from '@angular/router';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() isExpanded: boolean;
  @Input() isShowing: boolean;

  @Output() closeMenu = new EventEmitter<boolean>();

  public isCollapsed = false;
  public idModule: number;

  public listaMenu: Menu[];

  constructor(
    protected menuService: MenuService,
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.menuService.search$.next();

    this.menuService.data$.subscribe((result: Menu[]) => {
      this.listaMenu = result;
    });
  }

  openOption(url: string) {
    this.closeMenu.emit(true);
    this.router.navigateByUrl(url);
  }

  logout() {
    this.closeMenu.emit(true)
    this.authService.logout();
  }
}
