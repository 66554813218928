import { Component, OnInit } from '@angular/core';
import { Pedido } from '../../models/pedido';
import { PedidoService } from '../../services/pedido.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { AuthService } from 'src/app/auth/services/auth.service';

import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { Router } from '@angular/router';

const moment = _rollupMoment || _moment;


@Component({
  selector: 'app-pedido-list',
  templateUrl: './pedido-list.component.html',
  styleUrls: ['./pedido-list.component.scss']
})
export class PedidoListComponent implements OnInit {

  minDate: Date;
  maxDate: Date;

  _fechaIni = new Date();

  get fechaIni(): Date {
    return this._fechaIni;
  }

  set fechaIni(value: Date) {
    this._fechaIni = value;
    this.get();
  }

  _fechaFin = new Date();

  get fechaFin(): Date {
    return this._fechaFin;
  }

  set fechaFin(value: Date) {
    this._fechaFin = value;
    this.get();
  }

  listaPedido: Pedido[];
  // listaPedidoFiltrada: Pedido[];

  constructor(
    public service: PedidoService,
    private toastService: ToastService,
    public authService: AuthService,
    private router: Router
  ) {
    const fechaUltimoAnio = moment().add((-1), 'years');

    this.minDate = fechaUltimoAnio.toDate();
    this.maxDate = new Date();

    this.fechaIni = moment().add((-1), 'months').toDate();
  }

  ngOnInit() {
    this.get();
    // this.service.getAll(this.fechaIni, this.fechaFin).subscribe(
    //   data => {
    //     this.listaPedido = data;
    //   }, error => {
    //     this.toastService.showError(`Consulta de Pedido`, `Error: ${error}`);
    //   });
  }

  get totalPedidos(): number {
    if (this.listaPedido && this.listaPedido.length > 0) {
      return this.listaPedido.reduce((sum, current) => sum + current.total, 0);
    } else {
      return 0;
    }
  }


  get() {
    this.service.getAll(this._fechaIni, this._fechaFin).subscribe(
      data => {
        this.listaPedido = data;
      }, error => {
        this.toastService.showError(`Consulta de Pedido`, `Error: ${error}`);
      });
  }

  verPedido(pedido: Pedido) {
    this.router.navigate([`/Pedido/${pedido.idPedido}`]);
  }
}

