<app-loading-screen></app-loading-screen>
<div class="example-container">
  <mat-toolbar *ngIf="authService.isLoggedIn()" color="primary" class="example-toolbar">
    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="snav.toggle(); isExpanded = !isExpanded">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <div class="text-center">
      <p class="m-0" style="padding: 8px"><a class="" href="">
          <img style="height:25px" alt="ECommerce" src="assets/images/ECommerce.png"></a>
      </p>
    </div>
    <div class="flex-spacer"></div>
    <div class="d-flex justify-content-end">
      <div class="mx-2">
        <app-evento-boton *ngIf="authService.isLoggedIn()"></app-evento-boton>
      </div>
      <!-- <div class="mx-2">
        <app-novedad-boton *ngIf="authService.isLoggedIn()"></app-novedad-boton>
      </div> -->
      <div class="mx-2">
        <app-carro-boton *ngIf="authService.isLoggedIn()"></app-carro-boton>
      </div>
      <div>
        <button mat-button matTooltip="Cuenta">
          <span class="capitalize d-none d-sm-none d-md-block">
            <fa-icon [icon]="['fas', 'user']" size="2x" style="margin-left: 5px"></fa-icon>
            {{ !authService.getUser() ? "N/D" : authService.getUser().userName }}
          </span>
          <span class="capitalize d-block d-sm-block d-md-none" title="{{ !authService.getUser() ? 'N/D' : authService.getUser().userName }}">
            <fa-icon [icon]="['fas', 'user']" size="2x" style="margin-left: 5px"></fa-icon>

          </span>
        </button>
      </div>
    </div>
  </mat-toolbar>
  <mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav class="sidenav" id="sidenavMain" #snav [opened]="false" [mode]="'over'"
      [fixedInViewport]="(isHandset$ | async)" (click)="openMenu()">
      <app-menu *ngIf="authService.isLoggedIn()" [isExpanded]="isExpanded" [isShowing]="isShowing" (closeMenu)="closeMenu($event, snav)"></app-menu>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="!authService.isLoggedIn() ?'m-0' : ''">
      <main role="main" class="main">
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>
