import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalDeleteComponent } from './components/modal-delete/modal-delete.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { DateFormatPipe } from './pipes/date-format-pipe';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './interceptors/http-error.Interceptor';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { ControlValidationComponent } from './components/control-validation/control-validation.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DecimalCustomPipe } from './pipes/decimal-custom-pipe';
import { WrongRouteComponent } from './components/wrong-route/wrong-route.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeadersInterceptor } from './interceptors/headers.Interceptor';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';



@NgModule({
  declarations: [ModalDeleteComponent, ModalConfirmComponent, DateFormatPipe, DecimalCustomPipe, AutofocusDirective, ControlValidationComponent, WrongRouteComponent, ModalDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    FontAwesomeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    DateFormatPipe,
    DecimalCustomPipe],
  exports: [ModalDeleteComponent, ModalConfirmComponent, DateFormatPipe, DecimalCustomPipe, AutofocusDirective, ControlValidationComponent]
})
export class BaseModule { }
