import { Component, OnInit, Input } from '@angular/core';
import { ProductoService } from '../../services/producto.service';
import { Linea } from '../../models/linea';
import { SubLinea } from '../../models/sublinea';

@Component({
  selector: 'app-producto-filtro',
  templateUrl: './producto-filtro.component.html',
  styleUrls: ['./producto-filtro.component.scss']
})
export class ProductoFiltroComponent implements OnInit {
  @Input() serviceProducto: ProductoService;

  constructor() { }

  ngOnInit(): void {
  }

  lineaSeleccionada(linea: Linea) {
    if (linea.seleccionado) {
      if (!this.serviceProducto.lineasSeleccionadas.find(f => f.idLinea === linea.idLinea)) {
        this.serviceProducto.lineasSeleccionadas.push(linea);

        linea.subLineas.forEach(f => {
          f.seleccionado = false;
        });
        this.serviceProducto.subLineasSeleccionadas = this.serviceProducto.subLineasSeleccionadas.filter(f => f.idLinea !== linea.idLinea);
      }
    } else {
      this.serviceProducto.lineasSeleccionadas = this.serviceProducto.lineasSeleccionadas.filter(f => f !== linea);

      if (!linea.indeterminado) {
        linea.subLineas.forEach(f => {
          f.seleccionado = false;
        });
        this.serviceProducto.subLineasSeleccionadas = this.serviceProducto.subLineasSeleccionadas.filter(f => f.idLinea !== linea.idLinea);
      }
    }
    // this.filtrar(true);
  }

  removerFiltroLinea(linea: Linea) {
    linea.seleccionado = false;
    this.lineaSeleccionada(linea);
  }

  subLineaSeleccionada(sublinea: SubLinea) {
    if (sublinea.seleccionado) {
      this.serviceProducto.subLineasSeleccionadas.push(sublinea);
    } else {
      this.serviceProducto.subLineasSeleccionadas = this.serviceProducto.subLineasSeleccionadas.filter(f => f !== sublinea);
    }

    const totalSubLineasSeleccionadas = sublinea.linea.subLineas.filter(f => f.seleccionado).length;
    if (totalSubLineasSeleccionadas === 0) {
      sublinea.linea.indeterminado = false;
      sublinea.linea.seleccionado = false;

    } else if (sublinea.linea.subLineas.length > totalSubLineasSeleccionadas) {
      sublinea.linea.seleccionado = false;
      sublinea.linea.indeterminado = true;
    } else if (sublinea.linea.subLineas.length === totalSubLineasSeleccionadas) {
      sublinea.linea.indeterminado = false;
      sublinea.linea.seleccionado = true;
    }

    if (!sublinea.linea.seleccionado) {
      this.removerFiltroLinea(sublinea.linea);
    } else {
      if (!this.serviceProducto.lineasSeleccionadas.find(f => f.idLinea === sublinea.linea.idLinea)) {
        this.serviceProducto.lineasSeleccionadas.push(sublinea.linea);
      }
    }

//    this.filtrar(true);
  }

  removerFiltroSubLinea(subLinea: SubLinea) {
    subLinea.seleccionado = false;
    this.subLineaSeleccionada(subLinea);
  }
}
