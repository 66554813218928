import { NgModule, ModuleWithProviders } from '@angular/core'
import { OverlayModule } from '@angular/cdk/overlay';
import { ToastComponent } from './components/toast/toast.component';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [OverlayModule, CommonModule, FontAwesomeModule],
  declarations: [ToastComponent],
  entryComponents: [ToastComponent],
  exports: [ToastComponent]
})
 export class ToastModule {
 }
