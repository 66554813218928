import { IconName } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export class Menu {

  constructor() {}

  id: number;
  name: string;
  subName: string;
  active: boolean;
  open: boolean;
  icon: string;
  url: string;
  iconProp: IconProp;

  options: Menu[];
}
