import { Component, OnInit, Input } from '@angular/core';
import { Producto } from '../../models/producto';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CarritoService } from '../../services/carrito.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { ProductoService } from '../../services/producto.service';

@Component({
  selector: 'app-producto-detalle',
  templateUrl: './producto-detalle.component.html',
  styleUrls: ['./producto-detalle.component.scss']
})
export class ProductoDetalleComponent implements OnInit {

  @Input() producto: Producto;
  @Input() productosRelacionados: Producto[];

  constructor(
    public authService: AuthService,
    public serviceProducto: ProductoService,
    public serviceCarrito: CarritoService,
    private toastService: ToastService) { }

  ngOnInit(): void {
  }

  agregarCantidadProducto(producto: Producto) {
    if (producto.cantidadSolicitada > producto.cantidadMaxima) {
      producto.cantidadSolicitada = producto.cantidadMaxima;
    } else {
      producto.cantidadSolicitada = producto.cantidadSolicitada + producto.unidadesEmpaque;
    }
    this.actualizarCarro(producto);
  }

  removerCantidadProducto(producto: Producto) {
    if (producto.cantidadSolicitada > 1) {
      producto.cantidadSolicitada = producto.cantidadSolicitada - producto.unidadesEmpaque;
    } else {
      producto.cantidadSolicitada = 0;
    }
    this.actualizarCarro(producto);
  }

  borrarCantidadProducto(producto: Producto) {
    producto.cantidadSolicitada = 0;
    this.actualizarCarro(producto);
  }

  actualizarCarro(producto: Producto) {
    this.serviceCarrito.actualizarCarro(producto);

   if (this.totalPedido - this.authService.getUser().maxValueOrder > 0) {
      this.toastService.showWarning('Valor máximo pedido', `Necesita eliminar
        ${this.totalPedido - this.authService.getUser().maxValueOrder}
        en productos para no sobrepasar el valor máximo de pedido`, 2);
    }
  }

  get totalPedido(): number {

    let total = 0;

    if (this.serviceProducto.listaProducto) {
      total = this.serviceProducto.listaProducto.filter(f => f.cantidadSolicitada > 0).reduce((sum, current) => sum + current.total, 0);
    }

    return total;
  }

  verProducto(producto: Producto) {
    this.producto = producto;
  }
}
