export class User {
  companyName: string;
  userCust: string;
  userSuccli: string;
  userName: string;
  password: string;
  code: string;
  emailAddress: string;
  phone: string;
  approvedTerms: boolean;
  twoFactorAuth: boolean;
  takeOrders: boolean;
  minValueOrder: number;
  maxValueOrder: number;
  discountRate: number;
  taxRate: number;
}
