import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  private appType = environment.app_type;
  private appVersion = environment.app_version;

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addAppId(request);

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  private addAppId(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        AppVersion: `${this.appVersion}`,
        AppType: `${this.appType}`
      }
    });
  }
}
