import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;

export class Cartera {
  carCmpy: string;
  carCodusu: string;
  carTipo: string;
  carNumero: string;
  carCust: string;
  carSuccli: string;
  carGuia: string;
  carFecha: Date;
  carDiasven: number;
  carAmt: number;
  carSaldo: number;
  carNotacredito: number;
  carAplicanc: string;
  carFechaven: Date;

  verDetalle: boolean;
  get diasVencimiento(): number {
    return moment().diff(this.carFechaven, 'days');
  }

}
