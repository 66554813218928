import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { CarteraComponent } from './components/cartera/cartera.component';
import { BaseModule } from '../itdear-core/base/base.module';
import { ProductoComponent } from './components/producto/producto.component';
import { TermsComponent } from './components/terms/terms.component';
import { PromocionComponent } from './components/promocion/promocion.component';
import { NovedadComponent } from './components/novedad/novedad.component';
import { ProductoDetalleComponent } from './components/producto-detalle/producto-detalle.component';
import { CarroComponent } from './components/carro/carro.component';
import { PedidoListComponent } from './components/pedido-list/pedido-list.component';
import { PedidoComponent } from './components/pedido/pedido.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { ContactoEmpresaComponent } from './components/contacto-empresa/contacto-empresa.component';
import { CarroBotonComponent } from './components/carro-boton/carro-boton.component';
import { NovedadBotonComponent } from './components/novedad-boton/novedad-boton.component';
import { EventoComponent } from './components/evento/evento.component';
import { EventoBotonComponent } from './components/evento-boton/evento-boton.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ProductoFiltroComponent } from './components/producto-filtro/producto-filtro.component';

@NgModule({
  declarations: [CarteraComponent, ProductoComponent, TermsComponent, PromocionComponent, NovedadComponent, ProductoDetalleComponent, CarroComponent, PedidoListComponent,
    PedidoComponent, UsuarioComponent, ContactoEmpresaComponent, CarroBotonComponent, NovedadBotonComponent, EventoComponent, EventoBotonComponent, ProductoFiltroComponent],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    RouterModule,
    MatTooltipModule,
    MatPaginatorModule,
    CdkTableModule,
    MatTableModule,
    MatInputModule,
    MatDividerModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatTabsModule,
    DragDropModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatIconModule,
    BaseModule,
    MatListModule,
    MatChipsModule,
    MatBadgeModule,
    MatSidenavModule
  ],
  entryComponents: [],
  exports: [CarroComponent, CarroBotonComponent, NovedadBotonComponent, EventoBotonComponent]
})

export class CommerceModule { }
