<div class="content-main">
  <div class="d-flex form-section justify-content-end mt-1 px-3 py-1">
    <div>
      <button mat-raised-button color="warn" routerLink="" style="color: #ffffff;">Seguir comprando</button>
    </div>
  </div>
  <div class="d-flex form-section justify-content-between mt-1 p-3">
    <div>
      <h4 class="mb-0">Cartera<br><small>Consulta</small></h4>
    </div>
    <div class="form-group-search form-inline my-0">
      <div class="filtro">
        <div class="button-row">
          <button mat-flat-button (click)="mesesCartera = 0" [ngClass]="{'mat-primary': mesesCartera === 0}">Total
            cartera</button>
          <button mat-flat-button (click)="mesesCartera = 1" [ngClass]="{'mat-primary': mesesCartera === 1}">30
            días</button>
          <button mat-flat-button (click)="mesesCartera = 2" [ngClass]="{'mat-primary': mesesCartera === 2}">60
            días</button>
          <button mat-flat-button (click)="mesesCartera = 3" [ngClass]="{'mat-primary': mesesCartera === 3}">90
            días</button>
        </div>
        <div style="text-align: end;">
          <mat-form-field class="mx-1">
            <input matInput placeholder="Buscar" type="text" name="searchTerm" [(ngModel)]="filtro">
            <fa-icon matSuffix [icon]="['fas', 'search']"></fa-icon>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z2 form-section mt-1 p-3">
    <div class="d-flex justify-content-end text-primary">
      <h4>Total Cartera
        {{totalCartera | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency}}
      </h4>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr class="text-center">
            <th scope="col-3">
              <h4># Documento</h4>
            </th>
            <th scope="col-3">
              <h4>Fecha de vencimiento
                <small><small>{{authService.getCompany().formatDate.toLowerCase()}}</small></small></h4>
            </th>
            <th scope="col">
              <h4>D.V.</h4>
            </th>
            <th scope="col-3">
              <h4>Total</h4>
            </th>
            <th style="width: 50px;">
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of listaCarteraFiltrada">
            <tr>
              <td>
                <h4>
                  {{item.carTipo}}-{{item.carNumero}}</h4>
              </td>
              <td class="text-center">
                <h4>
                  {{item.carFechaven | date: authService.getCompany().formatDate}}</h4>
              </td>
              <td class="text-center">
                <h4>
                  {{item.diasVencimiento}}</h4>
              </td>
              <td class="text-right">
                <h4>
                  {{item.carSaldo | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                </h4>
              </td>
              <td class="text-center">
                <h4>
                  <fa-icon *ngIf="!item.verDetalle" [icon]="['fas', 'angle-down']" class="cursor"
                    matTooltip="Ver detalle" (click)="item.verDetalle = !item.verDetalle"></fa-icon>
                  <fa-icon *ngIf="item.verDetalle" [icon]="['fas', 'angle-up']" class="cursor"
                    matTooltip="Ocultar detalle" (click)="item.verDetalle = !item.verDetalle"></fa-icon>
                </h4>
              </td>
            </tr>
            <tr *ngIf="item.verDetalle">
              <td colspan="5" style="padding: 0;">
                <table class="table table-bordered float-right detalle-cartera">
                  <tr>
                    <td>Número de documento</td>
                    <td class="text-right">{{item.carTipo}}-{{item.carNumero}}</td>
                  </tr>
                  <tr>
                    <td>Fecha de vencimiento</td>
                    <td class="text-right">{{item.carFechaven | date: authService.getCompany().formatDate}}</td>
                  </tr>
                  <tr>
                    <td>Días de vencimiento</td>
                    <td class="text-right">{{item.diasVencimiento}}</td>
                  </tr>
                  <tr>
                    <td>Núm. Guía o Memo(NC)</td>
                    <td class="text-right">{{item.carGuia}}</td>
                  </tr>
                  <tr>
                    <td>Fecha de documento</td>
                    <td class="text-right">{{item.carFecha | date: authService.getCompany().formatDate}}</td>
                  </tr>
                  <tr>
                    <td>Valor inicial</td>
                    <td class="text-right">
                      {{item.carAmt | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
