import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    if (this.authService.isLoggedIn()) {

      if (!this.authService.approvedTerms()) {
        this.router.navigate(['/Terms']);
        return false;
      }

      return true;
    }
    this.router.navigate(['/Login']);
    return false;
  }
}
