import { Component, OnInit } from '@angular/core';
import { CarritoService } from '../../services/carrito.service';
import { Producto } from '../../models/producto';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Pedido } from '../../models/pedido';
import { PedidoDetalle } from '../../models/pedido-detalle';
import { PedidoService } from '../../services/pedido.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmComponent } from 'src/app/itdear-core/base/components/modal-confirm/modal-confirm.component';
import { Evento } from '../../models/evento';
import { EventoService } from '../../services/evento.service';
import { ProductoService } from '../../services/producto.service';

@Component({
  selector: 'app-carro',
  templateUrl: './carro.component.html',
  styleUrls: ['./carro.component.scss']
})
export class CarroComponent implements OnInit {

  listaCarrito: Producto[] = [];
  listaEvento: Evento[];

  constructor(
    public dialog: MatDialog,
    public carritoService: CarritoService,
    public pedidoService: PedidoService,
    public serviceEvento: EventoService,
    public authService: AuthService,
    public toastService: ToastService,
    private router: Router
  ) {

  }

  get subtotal(): number {
    return this.listaCarrito.reduce((sum, current) => sum + current.subTotal, 0);
  }

  get descuento(): number {
    return this.listaCarrito.reduce((sum, current) => sum + current.descuento, 0);
  }

  get totalAntesIva(): number {
    return this.listaCarrito.reduce((sum, current) => sum + current.totalAntesIva, 0);
  }

  get iva(): number {
    return this.listaCarrito.reduce((sum, current) => sum + current.iva, 0);
  }

  get total(): number {
    return this.listaCarrito.reduce((sum, current) => sum + current.total, 0);
  }


  ngOnInit(): void {
    if (!this.carritoService.dataCar.getValue()) {
      this.carritoService.searchCar$.next();
    }

    this.carritoService.dataCar$.subscribe((result: Producto[]) => {
      this.listaCarrito = result;
      this.actualizarEventosProducto();
    });

    if (!this.serviceEvento.data.getValue()) {
      this.serviceEvento.search$.next();
    }

    this.serviceEvento.data$.subscribe((result: Evento[]) => {
      this.listaEvento = result;
      this.actualizarEventosProducto();
    });
  }

  actualizarEventosProducto() {
    if (this.listaCarrito && this.listaEvento) {
      this.listaEvento.forEach(fe => {
        const p = this.listaCarrito.find(f => f.idProducto === fe.producto.idProducto);
        p.evento = fe;
      });
    }
  }

  agregarCantidadProducto(producto: Producto) {
    if (producto.cantidadSolicitada > producto.cantidadMaxima) {
      producto.cantidadSolicitada = producto.cantidadMaxima;
    } else {
      producto.cantidadSolicitada = producto.cantidadSolicitada + producto.unidadesEmpaque;
    }
    this.actualizarCarro(producto);
  }

  removerCantidadProducto(producto: Producto) {
    if (producto.cantidadSolicitada > 1) {
      producto.cantidadSolicitada = producto.cantidadSolicitada - producto.unidadesEmpaque;
    } else {
      producto.cantidadSolicitada = 0;
    }
    this.actualizarCarro(producto);
  }

  borrarCantidadProducto(producto: Producto) {
    producto.cantidadSolicitada = 0;
    this.actualizarCarro(producto);
  }

  actualizarCarro(producto: Producto) {
    this.carritoService.actualizarCarro(producto);

    if (this.total - this.authService.getUser().maxValueOrder > 0) {
      this.toastService.showWarning('Valor máximo pedido', `Necesita eliminar
        ${this.total - this.authService.getUser().maxValueOrder}
        en productos para no sobrepasar el valor máximo de pedido`, 2);
    }
  }

  confirmarPedido() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      width: '500px',
      disableClose: true,
      data: {
        title: 'Confirmar pedido',
        question: 'Esta seguro que desea confirmar este pedido',
        warning: 'Al confirmar se generara su pedido.',
        textButtonConfirm: 'Confirmar'
      }
    });
    dialogRef.afterClosed()
      .subscribe(async result => {
        if (result === true) {
          this.crearPedido();
        }
      });
  }

  crearPedido() {
    const pedido = new Pedido();
    pedido.porcDescuento = this.authService.getUser().discountRate;
    pedido.porcIva = this.authService.getUser().taxRate;
    pedido.observaciones = this.carritoService.observaciones;
    pedido.pedidoDetalle = [];

    this.listaCarrito.forEach(f => {
      const detalle = new PedidoDetalle();
      detalle.cantidad = f.cantidadSolicitada;
      detalle.valorUnitario = f.precio;
      detalle.aplicaIva = f.aplicaIva;
      detalle.porcDescuento = f.porcDescuento;
      detalle.porcIva = f.porcIva;
      detalle.producto = f;
      pedido.pedidoDetalle.push(detalle);
    });

    this.pedidoService.add(pedido)
      .subscribe(idPedido => this.handleSuccessfilSave(idPedido),
        err => {
          this.toastService.showError('Confirmando pedido', err);
        });
  }
  
  handleSuccessfilSave(idPedido: number) {
    this.listaCarrito.forEach(f => {
      f.cantidadSolicitada = 0;
    });
    this.carritoService.observaciones = '';
    this.carritoService.searchCar$.next();
    this.router.navigate([`/Pedido/${idPedido}`]);
  }

  serviceDelete() {
    this.pedidoService.delete(2).subscribe(
      _ => {
        this.toastService.showSuccess(
          `Tipo de visita`,
          `Tipo de visita eliminado`
        );
      },
      err => {
        this.toastService.showError(`Tipo de visita `, err);
      }
    );
  }
}
