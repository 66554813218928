<div class="content-main m-0" [hidden]="this.permiteCrearPedidos">
  <!-- *ngIf="!this.permiteCrearPedidos"> -->
  <div class="alert alert-warning" role="alert">
    No se encuentra habilitada la opción de creación de pedidos para el día de hoy
  </div>
</div>
<div class="content-main m-0" [hidden]="!this.permiteCrearPedidos">
  <!-- *ngIf="this.permiteCrearPedidos"> -->
  <div class="">
    <div class="row">
      <div class="col">
        <app-promocion></app-promocion>
      </div>
    </div>
    <div class="row">
      <mat-drawer-container class="example-container mat-elevation-z2 form-section mt-1 p-0 w-100"
        [hasBackdrop]="(isHandset$ | async)">
        <mat-drawer #drawer [mode]="(isHandset$ | async) ? 'over' : 'side' " style="width: 250px;"
          [opened]="!(isHandset$ | async)">
          <div>
            <div *ngIf="serviceProducto.listaLineas">
              <div>
                <mat-list role="list">
                  <mat-list-item role="listitem" class="px-3">
                    <h6>Líneas de producto</h6>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                  <div
                    *ngIf="serviceProducto.lineasSeleccionadas.length > 0 || serviceProducto.subLineasSeleccionadas.length > 0"
                    class="p-3">
                    <mat-chip-list aria-label="Líneas seleccionadas">
                      <mat-chip color="primary" class="mat-chip-custom" selected
                        *ngFor="let linea of serviceProducto.lineasSeleccionadas" (removed)="removerFiltroLinea(linea)">
                        {{linea.descLinea}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                    <mat-chip-list aria-label="Sublíneas seleccionadas">
                      <mat-chip color="accent" class="mat-chip-custom" selected
                        *ngFor="let subLinea of serviceProducto.subLineasSeleccionadas"
                        matTooltip="{{subLinea.descSubLinea}} - {{subLinea.linea.descLinea}}"
                        (removed)="removerFiltroSubLinea(subLinea)">
                        {{subLinea.descSubLinea}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                  <mat-divider *ngIf="serviceProducto.lineasSeleccionadas.length > 0"></mat-divider>
                  <ng-container *ngFor="let linea of serviceProducto.listaLineas">
                    <mat-list-item role="listitem" class="p-3 w-100">
                      <mat-checkbox class="mr-auto" [checked]="linea.seleccionado" (change)="lineaSeleccionada(linea)"
                        [(ngModel)]="linea.seleccionado" [(indeterminate)]="linea.indeterminado">{{linea.descLinea}}
                      </mat-checkbox>
                      <fa-icon *ngIf="!linea.verDetalle" [icon]="['fas', 'angle-down']"
                        (click)="linea.verDetalle = !linea.verDetalle" matTooltip="Ver sublineas"></fa-icon>
                      <fa-icon *ngIf="linea.verDetalle" [icon]="['fas', 'angle-up']"
                        (click)="linea.verDetalle = !linea.verDetalle" matTooltip="Ocultar sublineas"></fa-icon>
                    </mat-list-item>
                    <ng-container *ngIf="linea.verDetalle">
                      <mat-list-item role="listitem" *ngFor="let sublinea of linea.subLineas" class="ml-3">
                        <mat-checkbox class="ml-3" [checked]="sublinea.seleccionado"
                          (change)="subLineaSeleccionada(sublinea)" [(ngModel)]="sublinea.seleccionado">
                          {{sublinea.descSubLinea}}</mat-checkbox>
                      </mat-list-item>
                    </ng-container>
                  </ng-container>
                </mat-list>
              </div>
            </div>
          </div>
        </mat-drawer>
        <mat-drawer-content>
          <div class="col mat-elevation-z2 form-section mt-1 p-3">
            <div class="row">
              <div class="col">
                <div class="" style="max-width: 100px;">
                  <mat-form-field>
                    <mat-label>Ordenar por:</mat-label>
                    <mat-select [(value)]="serviceProducto.idOrdenamiento" (valueChange)="sort()">
                      <mat-option [value]="1">Precio de menor a mayor</mat-option>
                      <mat-option [value]="2">Precio de mayor a menor</mat-option>
                      <mat-option [value]="3">Línea</mat-option>
                      <mat-option [value]="4">Sublínea</mat-option>
                      <mat-option [value]="5">Nombre de producto</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div style="font-family: Roboto, monospace;font-size: 12px;color: rgba(0, 0, 0, 0.54);">
                    {{serviceProducto.pageEvent.length}}
                    productos</div>
                </div>
              </div>
              <div class="col d-none d-lg-none d-xl-block">
                <div class="text-muted p-4" style="width: 250px;">
                  <div style="font-family: Roboto, monospace;font-size: 12px;color: rgba(0, 0, 0, 0.54);">
                    <div class="d-flex justify-content-between text-muted">
                      <div>Valor min pedido</div>
                      <div>
                        {{authService.getUser().minValueOrder  | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                      </div>
                    </div>
                  </div>
                  <div style="font-family: Roboto, monospace;font-size: 12px;color: rgba(0, 0, 0, 0.54);">
                    <div class="d-flex justify-content-between text-muted">
                      <div>Valor max pedido</div>
                      <div>
                        {{authService.getUser().maxValueOrder  | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between text-muted">
                      <div>Valor pedido</div>
                      <div>
                        {{totalPedido  | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between text-muted" *ngIf="authService.getUser().maxValueOrder - totalPedido >= 0">
                      <div>Valor disponible</div>
                      <div>
                        {{authService.getUser().maxValueOrder - totalPedido  | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between text-muted" *ngIf="authService.getUser().maxValueOrder - totalPedido < 0">
                      <div class="alert alert-warning p-0 m-0" role="alert">
                        Necesita eliminar
                        {{totalPedido - authService.getUser().maxValueOrder | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                        en productos para no sobrepasar el valor máximo de pedido
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col row">
                <div class="col" style="text-align: end;">
                  <mat-form-field class="mx-1">
                    <input matInput placeholder="Buscar" type="text" name="searchTerm" [(ngModel)]="filtro">
                    <fa-icon *ngIf="filtro === ''" matSuffix [icon]="['fas', 'search']"></fa-icon>
                    <fa-icon *ngIf="filtro !== ''" matSuffix [icon]="['fas', 'times']" class="cursor"
                      (click)="filtro = ''"></fa-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-auto ml-auto">
                  <div class="vista-lista" style="text-align: end;">
                    <fa-icon [icon]="['fas', 'filter']" class="cursor m-2" *ngIf="(isHandset$ | async)"
                      (click)="drawer.toggle()"
                      matTooltip="Filtrar">
                    </fa-icon>
                    <fa-icon [icon]="['fas', 'list']" class="cursor m-2"
                      [ngClass]="{'vista-lista-seleccionada': this.serviceProducto.modoLista}"
                      (click)="this.serviceProducto.modoLista = !this.serviceProducto.modoLista" matTooltip="Lista">
                    </fa-icon>
                    <fa-icon [icon]="['fas', 'th']" class="cursor m-2"
                      [ngClass]="{'vista-lista-seleccionada': !this.serviceProducto.modoLista}"
                      (click)="this.serviceProducto.modoLista = !this.serviceProducto.modoLista"
                      matTooltip="Cuadricula">
                    </fa-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="row text-muted d-lg-block d-xl-none">
              <div class="col-sm-6 col-md-4">
                <div style="font-family: Roboto, monospace;font-size: 12px;color: rgba(0, 0, 0, 0.54);">
                  <div class="d-flex justify-content-between text-muted">
                    <div>Valor min pedido</div>
                    <div>
                      {{authService.getUser().minValueOrder  | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div style="font-family: Roboto, monospace;font-size: 12px;color: rgba(0, 0, 0, 0.54);">
                  <div class="d-flex justify-content-between text-muted">
                    <div>Valor max pedido</div>
                    <div>
                      {{authService.getUser().maxValueOrder  | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div style="font-family: Roboto, monospace;font-size: 12px;color: rgba(0, 0, 0, 0.54);">
                  <div class="d-flex justify-content-between text-muted">
                    <div style="width: 150px;">Valor pedido</div>
                    <div>
                      {{totalPedido  | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4" *ngIf="totalPedido - authService.getUser().maxValueOrder <= 0">
                <div style="font-family: Roboto, monospace;font-size: 12px;color: rgba(0, 0, 0, 0.54);">
                  <div class="d-flex justify-content-between text-muted">
                    <div style="width: 150px;">Valor disponible</div>
                    <div>
                      {{ authService.getUser().maxValueOrder - totalPedido  | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4" *ngIf="totalPedido - authService.getUser().maxValueOrder > 0">
                <div class="d-flex justify-content-between text-muted" style="font-family: Roboto, monospace;font-size: 12px;">
                  <div class="alert alert-warning p-0 m-0" role="alert">
                    Necesita eliminar
                    {{totalPedido - authService.getUser().maxValueOrder | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                    en productos para no sobrepasar el valor máximo de pedido
                  </div>
                </div>
              </div></div>
           <div *ngIf="this.serviceProducto.modoLista" class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="text-center">
                    <th scope="col" colspan="2" style="border-top: none;">
                      <h4>Producto</h4>
                    </th>
                    <th scope="col-2" style="border-top: none;">
                      <h4>Línea</h4>
                    </th>
                    <th scope="col-2" style="border-top: none;">
                      <h4>Sublínea</h4>
                    </th>
                    <th style="border-top: none;min-width: 120px;">
                      <h4>Precio</h4>
                    </th>
                    <th style="border-top: none;width: 120px;">
                      <h4>Cantidad</h4>
                    </th>
                    <th style="border-top: none;min-width: 120px;">
                      <h4>Total</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of serviceProducto.listaProductoPaginada">
                    <tr>
                      <td style="width: 100px;" (click)="verProducto(item)" class="cursor">
                        <img src="{{item.urlImagen}}" height="100px"
                          onError="this.src='assets/images/not_found_product.jpg'">
                      </td>
                      <td style="font-weight: 500;">
                        <div *ngIf="item.evento" style="font-size: smaller;"
                          matTooltip="Evento valido hasta el {{ item.evento.fechaFin | date: authService.getCompany().formatDate}}">
                          <fa-icon class="icon-tag" matSuffix [icon]="['fas', 'tag']" size="lg">
                          </fa-icon> {{item.evento.descEvento}}
                        </div>
                        {{item.descProducto}}<br>
                        <small><small>COD</small> {{item.idProducto}}</small><br>
                        <small>
                          <fa-icon matSuffix [icon]="['fas', 'barcode']">
                          </fa-icon> {{item.ean}}
                        </small>
                      </td>
                      <td>
                        {{item.descLinea}}
                      </td>
                      <td>
                        {{item.descSubLinea}}
                      </td>
                      <td class="text-right">
                        {{item.precio | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                      </td>
                      <td>
                        <div>
                          <div class="w-100" style="min-width: 130px;">
                            <button *ngIf="item.cantidadSolicitada === 0" mat-raised-button class="btn-agregar w-100"
                              (click)="agregarCantidadProducto(item)">Agregar</button>
                            <div *ngIf="item.cantidadSolicitada > 0" class="input-group">
                              <div class="input-group-prepend">
                                <button class="btn btn-agregar btn-sm" (click)="borrarCantidadProducto(item)">
                                  <fa-icon matSuffix [icon]="['fas', 'trash-alt']">
                                  </fa-icon>
                                </button>
                              </div>
                              <div class="input-group-prepend" *ngIf="item.cantidadSolicitada > 1">
                                <button class="btn btn-agregar btn-sm" (click)="removerCantidadProducto(item)">
                                  <fa-icon matSuffix [icon]="['fas', 'minus']"></fa-icon>
                                </button>
                              </div>
                              <input type="text" class="form-control px-1 text-center" placeholder="Cantidad"
                                aria-label="Cantidad" aria-describedby="btnCantidad"
                                [(ngModel)]="item.cantidadSolicitada" disabled>
                              <div class="input-group-append">
                                <button class="btn btn-agregar btn-sm" (click)="agregarCantidadProducto(item)">
                                  <fa-icon matSuffix [icon]="['fas', 'plus']"></fa-icon>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between" style="font-size: x-small;"
                            *ngIf="item.unidadesEmpaque > 1 && item.cantidadSolicitada > 0">
                            <div>
                              Número de cajas
                            </div>
                            <div>
                              {{item.cantidadSolicitada / item.unidadesEmpaque}}
                            </div>
                          </div>
                          <div class="d-flex justify-content-between text-muted" style="font-size: x-small;">
                            <div>
                              Cantidad máxima
                            </div>
                            <div>
                              {{item.cantidadMaxima}}
                            </div>
                          </div>
                          <div class="d-flex justify-content-between text-muted" style="font-size: x-small;">
                            <div>
                              Unidad de empaque
                            </div>
                            <div>
                              {{item.unidadesEmpaque}}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-right" style="font-weight: 500;">
                        {{item.total | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div *ngIf="!this.serviceProducto.modoLista" class="row">
              <div class="col-xl-3 col-lg-4 col-sm-6  p-0" style="background-color: #fafafa; height: 100%;"
                *ngFor="let item of serviceProducto.listaProductoPaginada">
                <div class="m-1"
                  style="border: 1px solid #e6e6e6; min-height: 400px;background-color: white; padding: 15px; margin: 5px; ">
                  <div style="min-height: 250px;" class="cursor" (click)="verProducto(item)">
                    <img src="{{item.urlImagenGrande}}" width="100%"
                      onError="this.src='assets/images/not_found_product.jpg'">
                    <div *ngIf="item.evento" style="font-size: smaller;"
                      matTooltip="Evento valido hasta el {{ item.evento.fechaFin | date: authService.getCompany().formatDate}}">
                      <fa-icon class="icon-tag" matSuffix [icon]="['fas', 'tag']" size="lg">
                      </fa-icon> {{item.evento.descEvento}}
                    </div>
                  </div>
                  <div style="background-color: white;">
                    <h5 class="m-0">
                      {{item.descLinea}}
                    </h5>
                    <p class="text-sublinea">{{item.descSubLinea}}</p>
                    <div style="min-height: 42px;">
                      <h5 class="m-0" style="font-weight: 400;">{{item.descProducto}} </h5>
                    </div>
                    <div class="d-flex justify-content-between text-muted">
                      <small><small>COD</small> {{item.idProducto}}</small>
                      <small>
                        <fa-icon matSuffix [icon]="['fas', 'barcode']">
                        </fa-icon> {{item.ean}}
                      </small>
                    </div>
                    <div class="d-flex justify-content-between mt-0">
                      <div>
                        <div style="width: 130px;">
                          <button *ngIf="item.cantidadSolicitada === 0" mat-raised-button class="btn-agregar w-100"
                            (click)="agregarCantidadProducto(item)">Agregar</button>
                          <div *ngIf="item.cantidadSolicitada > 0" class="input-group w-100">
                            <div class="input-group-prepend">
                              <button class="btn btn-agregar btn-sm" (click)="borrarCantidadProducto(item)">
                                <fa-icon matSuffix [icon]="['fas', 'trash-alt']">
                                </fa-icon>
                              </button>
                            </div>
                            <div class="input-group-prepend" *ngIf="item.cantidadSolicitada > 1">
                              <button class="btn btn-agregar btn-sm" (click)="removerCantidadProducto(item)">
                                <fa-icon matSuffix [icon]="['fas', 'minus']"></fa-icon>
                              </button>
                            </div>
                            <input type="text" class="form-control px-1 text-center" style="width: 50px;"
                              placeholder="Cantidad" aria-label="Cantidad" aria-describedby="btnCantidad"
                              [(ngModel)]="item.cantidadSolicitada" disabled>
                            <div class="input-group-append">
                              <button class="btn btn-agregar btn-sm" (click)="agregarCantidadProducto(item)">
                                <fa-icon matSuffix [icon]="['fas', 'plus']"></fa-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between" style="font-size: x-small;"
                          *ngIf="item.unidadesEmpaque > 1 && item.cantidadSolicitada > 0">
                          <div>
                            Número de cajas
                          </div>
                          <div>
                            {{item.cantidadSolicitada / item.unidadesEmpaque}}
                          </div>
                        </div>
                        <div class="d-flex justify-content-between text-muted" style="font-size: x-small;">
                          <div class="mr-1">
                            Cant max {{item.cantidadMaxima}}
                          </div>
                          <div matTooltip="Unidades de empaque">
                            U Empaque {{item.unidadesEmpaque}}
                          </div>
                        </div>
                      </div>
                      <div class="text-right">
                        <b>{{item.precio | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <mat-paginator [length]="this.serviceProducto.pageEvent.length"
              [pageSize]="this.serviceProducto.pageEvent.pageSize"
              [pageSizeOptions]="this.serviceProducto.pageSizeOptions" (page)="pageEvent = $event">
            </mat-paginator>
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  </div>
</div>
