import { Component, OnInit } from '@angular/core';
import { ContactoEmpresa } from '../../models/contacto-empresa';
import { ContactoEmpresaService } from '../../services/contacto-empresa.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';

@Component({
  selector: 'app-contacto-empresa',
  templateUrl: './contacto-empresa.component.html',
  styleUrls: ['./contacto-empresa.component.scss']
})
export class ContactoEmpresaComponent implements OnInit {

  listaContactos: ContactoEmpresa[];

  constructor(
    public service: ContactoEmpresaService,
    private toastService: ToastService) { }

  ngOnInit(): void {
    this.service.getAll().subscribe(
      data => {

        this.listaContactos = data;
      }, error => {
        this.toastService.showError(`Consulta de contactos`, `Error: ${error}`);
      });
  }
}
