<div class="content-main m-10">
  <div class="d-flex form-section justify-content-end mt-1 px-3 py-1">
    <div>
      <button mat-raised-button color="warn" routerLink="" style="color: #ffffff;" >Seguir comprando</button>
    </div>
  </div>
  <div class="d-flex form-section justify-content-between mt-1 p-3">
    <div>
      <h4 class="mb-0">Novedades<br><small>Consulta</small></h4>
    </div>
  </div>
  <div class="mat-elevation-z2 form-section mt-1 p-3">
    <div *ngIf="listaNovedades && listaNovedades.length > 0">
      <div class="row m-3">
        <div class="col">
          <img src="{{listaNovedades[position].rutaImagen}}" class="center-image">
        </div>
        <div class="col">
          <mat-card>
            <div class="d-flex justify-content-between mat-card-title">
              <div>Novedades de producto </div>
              <div>
                <div class="text-muted">   {{ position+1}}/{{listaNovedades.length}}</div>
              </div>
            </div>
            <mat-divider></mat-divider>
            <mat-card-content class="div-description">
              <p class="mt-3">
                {{listaNovedades[position].descNovedad}}
              </p>
            </mat-card-content>
            <mat-card-actions *ngIf="listaNovedades.length > 1">
              <div class="d-flex justify-content-end">
                <button mat-raised-button color="primary" (click)="previousImage(listaNovedades[position])">
                  <fa-icon [icon]="['fas', 'angle-double-left']"></fa-icon> Anterior
                </button>
                <button mat-raised-button color="primary" (click)="nextImage(listaNovedades[position])">
                  Siguiente <fa-icon [icon]="['fas', 'angle-double-right']"></fa-icon> </button>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
    <div *ngIf="!listaNovedades || listaNovedades.length === 0" class="alert alert-warning" role="alert">
      Sin novedades!
    </div>
  </div>
</div>
