<div class="content-main">
  <div class="d-flex form-section justify-content-end mt-1 px-3 py-1">
    <div>
      <button mat-raised-button color="warn" routerLink="" style="color: #ffffff;" >Seguir comprando</button>
    </div>
  </div>
  <div class="d-flex form-section justify-content-between mt-1 p-3">
    <div>
      <h4 class="mb-0">Pedido<br><small>Consulta</small></h4>
    </div>
    <div class="form-group-search form-inline my-0">
      <div class="button-row">
        <div class="col filtro">
          <mat-form-field>
            <mat-label>Fecha inicial</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="pickerIni" [(ngModel)]="fechaIni">
            <mat-datepicker-toggle matSuffix [for]="pickerIni"></mat-datepicker-toggle>
            <mat-datepicker #pickerIni></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="ml-2">
            <mat-label>Fecha final</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="pickerFin" [(ngModel)]="fechaFin">
            <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
            <mat-datepicker #pickerFin></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z2 form-section mt-1 p-3">
    <div class="d-flex justify-content-end text-primary">
      <h4>Total Pedidos
        {{totalPedidos | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency}}
      </h4>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr class="text-center">
            <th style="width: 50px;"></th>
            <th scope="col-3">
              <h4># Pedido</h4>
            </th>
            <th scope="col-3">
              <h4>Fecha <small><small>{{authService.getCompany().formatDate.toLowerCase()}}</small></small></h4>
            </th>
            <th scope="col">
              <h4>Estado</h4>
            </th>
            <th scope="col-3" class="col-total">
              <h4>Total</h4>
            </th>
            <th style="width: 50px;">
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let pedido of listaPedido">
            <tr>
              <td class="text-center">
                <h4>
                  <fa-icon [icon]="['fas', 'search']" class="cursor" matTooltip="Ver pedido"
                    (click)="verPedido(pedido)"></fa-icon>
                </h4>
              </td>
              <td class="text-center">
                <h4>
                  {{pedido.idPedido}}</h4>
              </td>
              <td class="text-center">
                <h4>
                  {{pedido.fecha | date: authService.getCompany().formatDate}}</h4>
              </td>
              <td class="text-center">
                <h4>
                  {{pedido.cerrado ? 'Cerrado' : 'Procesado'}}</h4>
              </td>
              <td class="text-right">
                <h4>
                  {{pedido.total | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                </h4>
              </td>
              <td class="text-center">
                <h4>
                  <fa-icon *ngIf="!pedido.verDetalle" [icon]="['fas', 'angle-down']" class="cursor"
                    matTooltip="Ver detalle" (click)="pedido.verDetalle = !pedido.verDetalle"></fa-icon>
                  <fa-icon *ngIf="pedido.verDetalle" [icon]="['fas', 'angle-up']" class="cursor"
                    matTooltip="Ocultar detalle" (click)="pedido.verDetalle = !pedido.verDetalle"></fa-icon>
                </h4>
              </td>
            </tr>
            <tr *ngIf="pedido.verDetalle">
              <td colspan="5" style="padding: 0;">
                <table class="table table-borderless">
                  <tbody>
                    <ng-container *ngFor="let item of pedido.pedidoDetalle">
                      <tr>
                        <td style="width: 100px;">
                          <img src="{{item.producto.urlImagen}}" class="imagen-producto"
                            onError="this.src='assets/images/not_found_product.jpg'">
                        </td>
                        <td style="font-weight: 500;">
                          <div class="w-100">{{item.producto.descProducto}}</div>
                          <div class="w-100">{{item.producto.descLinea}} - {{item.producto.descSubLinea}}
                          </div>
                          <small><small>COD</small> {{item.producto.idProducto}}</small><br>
                          <small><fa-icon matSuffix [icon]="['fas', 'barcode']">
                          </fa-icon> {{item.producto.ean}}</small>
                        </td>
                        <td class="cantidad-producto">
                          <div>
                            <h4>{{item.cantidad | decimalCustomPipe : authService.getCompany().formatNumber : '' }}</h4>
                          </div>
                        </td>
                        <td class="text-right" style="font-weight: 500; min-width: 120px;">
                          <div class="d-flex justify-content-end" matTooltip="Valor total">
                            <!-- <h2>Total</h2> -->
                            <h4>
                              {{item.subTotal | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                            </h4>
                          </div>
                          <div class="d-flex justify-content-end text-muted" matTooltip="Valor unitario">
                            {{item.valorUnitario | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
