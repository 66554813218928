import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { LoginComponent } from './auth/components/login/login.component';
import { HomeComponent } from './shared/components/home/home.component';
import { ResetComponent } from './auth/components/reset/reset.component';
import { AssingPasswordComponent } from './auth/components/assing-password/assing-password.component';
import { ChangePasswordComponent } from './auth/components/change-password/change-password.component';
import { WrongRouteComponent } from './itdear-core/base/components/wrong-route/wrong-route.component';
import { CarteraComponent } from './commerce/components/cartera/cartera.component';
import { ProductoComponent } from './commerce/components/producto/producto.component';
import { TermsComponent } from './commerce/components/terms/terms.component';
import { NovedadComponent } from './commerce/components/novedad/novedad.component';
import { CarroComponent } from './commerce/components/carro/carro.component';
import { PedidoListComponent } from './commerce/components/pedido-list/pedido-list.component';
import { PedidoComponent } from './commerce/components/pedido/pedido.component';
import { UsuarioComponent } from './commerce/components/usuario/usuario.component';
import { ContactoEmpresaComponent } from './commerce/components/contacto-empresa/contacto-empresa.component';
import { EventoComponent } from './commerce/components/evento/evento.component';

const routes: Routes = [
  // { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'Login', component: LoginComponent },
  { path: 'Reset', component: ResetComponent },
  { path: 'Reset/:token', component: AssingPasswordComponent },
  { path: 'ChangePassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },


  { path: 'Terms', component: TermsComponent },
  { path: 'Terms/Consulta', component: TermsComponent },
  { path: 'Novedad', component: NovedadComponent, canActivate: [AuthGuard] },

  { path: 'Contacto', component: ContactoEmpresaComponent, canActivate: [AuthGuard] },

  { path: 'Usuario', component: UsuarioComponent, canActivate: [AuthGuard] },

  { path: 'Cartera', component: CarteraComponent, canActivate: [AuthGuard] },
  { path: 'Producto', component: ProductoComponent, canActivate: [AuthGuard] },

  { path: '', component: ProductoComponent, canActivate: [AuthGuard] },

  { path: 'Cart', component: CarroComponent, canActivate: [AuthGuard] },

  { path: 'Pedido/t/:companyId/:token', component: PedidoComponent },

  { path: 'Pedido/:idPedido', component: PedidoComponent, canActivate: [AuthGuard] },

  { path: 'Pedido', component: PedidoListComponent, canActivate: [AuthGuard] },

  { path: 'Evento', component: EventoComponent, canActivate: [AuthGuard] },

  // Para cualquier ruta que no este previamente configurada, siempre debe quedar de última
  { path: '**', component: WrongRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
