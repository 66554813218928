import { Evento } from './evento';

export class Producto {
  idProducto: string;
  descProducto: string;
  idLinea: string;
  descLinea: string;
  idSubLinea: string;
  descSubLinea: string;
  nuevo: number;
  urlImagen: string;
  urlImagenGrande: string;
  ean: string;
  precio: number;
  impuesto: string;
  porcDescuento: number;
  porcIva: number;
  evento: Evento;

  get aplicaIva(): boolean {
    return this.impuesto.toLowerCase().trim() === 'y';
  }

  get subTotal(): number {
    return this.precio * this.cantidadSolicitada;
  }

  get descuento(): number {
    return this.subTotal * this.porcDescuento / 100;
  }

  get totalAntesIva(): number {
    return this.subTotal - this.descuento;
  }

  get iva(): number {
    if (!this.aplicaIva) {
      return 0;
    }
    return this.totalAntesIva * this.porcIva / 100;
  }

  get total(): number {
    return this.totalAntesIva + this.iva;
  }


  cantidadMaxima: number;
  unidadesEmpaque: number;
  _cantidadSolicitada: number = 0;

  get cantidadSolicitada(): number {
    return this._cantidadSolicitada;
  }

  set cantidadSolicitada(value: number) {
    value = Number(value);
    if (!isNaN(value) && value >= 0 && this.cantidadMaxima >= value) {
      this._cantidadSolicitada = value;
    } else {
      this.cantidadSolicitada = this._cantidadSolicitada;
    }
  }
}
