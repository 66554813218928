import { Producto } from './producto';

export class PedidoDetalle {
  producto: Producto;
  cantidad: number;
  valorUnitario: number;
  aplicaIva: boolean;
  porcDescuento: number;
  porcIva: number;

  get subTotal(): number {
    return this.valorUnitario * this.cantidad;
  }

  get descuento(): number {
    return this.subTotal * this.porcDescuento / 100;
  }

  get totalAntesIva(): number {
    return this.subTotal - this.descuento;
  }

  get iva(): number {
    if (!this.aplicaIva) {
      return 0;
    }
    return this.totalAntesIva * this.porcIva / 100;
  }

  get total(): number {
    return this.totalAntesIva + this.iva;
  }

}
