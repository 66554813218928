import { Producto } from './producto';

export class Evento {

  idTipo: string;

  fechaIni: Date;

  fechaFin: Date;

  descEvento: string;

  producto: Producto;

}
