import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService, MessageType } from 'src/app/itdear-core/message/services/message.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TerminosService {

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    protected messageService: MessageService,
    protected toastService: ToastService,
    protected authService: AuthService) {

  }

  aceptar(): Observable<any> {
    return this.http.post(`${environment.apiURLSEG}/${environment.controller_Terminos}`, this.httpOptions)
      .pipe(
        tap(_ => {
          this.messageService.log('Aceptar', 'TerminosService', 'Aceptando terminos y condiciones', MessageType.Success);
        }
        ),
        catchError(err => {
          this.messageService.handleError('Aceptar', 'TerminosService', 'Aceptando terminos y condiciones');
          return throwError(err);
        })
      );
  }
}
