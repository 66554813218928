import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { User } from '../../models/user';
import { ParametersService } from 'src/app/commerce/services/parameters.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginCodeForm: FormGroup;
  hide = true;
  twoFactorAuth: boolean = false;
  user: User;

  urlAppStoreAndroid: string;
  urlAppStoreIOS: string;

  constructor(
    private authService: AuthService,
    private parametersService: ParametersService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastService: ToastService) {
    this.urlAppStoreAndroid = environment.urlAppStoreAndroid;
    this.urlAppStoreIOS = environment.urlAppStoreIOS;
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.authService.logout();
    }

    this.loginForm = this.formBuilder.group({
      usercust: ['', Validators.required],
      usersuccli: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.loginCodeForm = this.formBuilder.group({
      code: ['', Validators.required]
    });
  }



  login() {

    this.user = new User();

    this.user.userCust = this.loginForm.controls.usercust.value;
    // this.user.userSuccli = this.loginForm.controls.usersuccli.value;
    this.user.userSuccli = this.loginForm.controls.usercust.value;
    this.user.password = this.loginForm.controls.password.value;

    this.authService.login(this.user)
      .subscribe(user => {
        if (!user.twoFactorAuth) {
          this.getParameters(user.company.urlApi);
        } else {
          this.twoFactorAuth = true;
        }
      },
        err => {
          this.toastService.showError('Inicio sesión', err);
        });
  }

  getParameters(urlApi: string) {
    this.parametersService.get(urlApi).subscribe(
      par => {
        this.router.navigateByUrl('/');
      }, err => {
        this.toastService.showError('Error al consultar los parametros del usuario', err);
      });
  }


  login2fa() {
    this.user.code = this.loginCodeForm.controls.code.value;
    this.authService.login2fa(this.user)
      .subscribe(user => {
        if (!user.twoFactorAuth) {
          this.getParameters(user.company.urlApi);
        } else {

        }
      },
        err => {
          this.toastService.showError('Inicio sesión', err);
        });
  }

  replicatelogin(eventlogin) {
    this.loginForm.get('usersuccli').setValue(eventlogin);
  }

  reset() {
    this.router.navigateByUrl('/Reset');
  }
}
