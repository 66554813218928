import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService, MessageType } from 'src/app/itdear-core/message/services/message.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { Producto } from '../models/producto';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PageEvent } from '@angular/material/paginator';
import { Linea } from '../models/linea';
import { SubLinea } from '../models/sublinea';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  userCust: string;
  userSuccli: string;
  fechaConsulta: Date;
  idOrdenamiento: number = 3;
  textoBusqueda: string = '';
  pagina: number;
  registroPorPagina: number;

  modoLista = true;

  pageSizeOptions: number[] = [10, 20, 50, 100];
  _pageEvent: PageEvent;

  get pageEvent(): PageEvent {
    if (!this._pageEvent) {
      this._pageEvent = new PageEvent();
      this._pageEvent.pageIndex = 0;
      this._pageEvent.length = 0;
      this._pageEvent.pageSize = 20;
    }
    return this._pageEvent;
  }

  listaProducto: Producto[];
  listaProductoFiltrada: Producto[];
  listaProductoPaginada: Producto[];

  productosCargados: boolean = false;

  listaLineas: Linea[];
  lineasCargadas: boolean = false;

  lineasSeleccionadas: Linea[] = [];

  subLineasSeleccionadas: SubLinea[] = [];

  constructor(
    private http: HttpClient,
    protected authService: AuthService,
    protected messageService: MessageService,
    protected toastService: ToastService) {

  }

  getAll(): Observable<Producto[]> {
    const url = `${this.authService.getUrlApi()}${environment.controller_Producto}`;
    return this.http.get<Producto[]>(url, this.httpOptions).pipe(
      tap(_ =>
        this.messageService.log(
          'Producto',
          'ProductoService',
          'Consultando productos',
          MessageType.Success
        )
      ),
      map<Producto[], Producto[]>(items => {
        if (items) {
          return this.cargarProductos(items);
        } else {
          return null;
        }
      }),
      catchError(err => {
        this.messageService.handleError<Producto[]>('Producto', 'ProductoService', 'Consultando productos');
        return throwError(err);
      })
    );
  }

  private cargarProductos(items: Producto[]): Producto[] {
    const listaProductos: Producto[] = [];

    items.forEach(f => {
      const producto = new Producto();
      producto.idProducto = f.idProducto;
      producto.descProducto = f.descProducto ?? '';
      producto.idLinea = f.idLinea;
      producto.descLinea = f.descLinea ?? '';
      producto.idSubLinea = f.idSubLinea;
      producto.descSubLinea = f.descSubLinea ?? '';
      producto.nuevo = f.nuevo;
      producto.urlImagen = f.urlImagen ?? '';
      producto.urlImagenGrande = this.rutaImagenGrande(f.urlImagen);
      producto.ean = f.ean ?? '';
      producto.precio = f.precio;
      producto.impuesto = f.impuesto;
      producto.porcDescuento = this.authService.getUser().discountRate;
      producto.porcIva = producto.aplicaIva ? this.authService.getUser().taxRate : 0;
      producto.cantidadMaxima = f.cantidadMaxima;
      producto.unidadesEmpaque = f.unidadesEmpaque;

      listaProductos.push(producto);
    });
    return listaProductos;
  }

  private rutaImagenGrande(ruta: string): string {
    const posicionNombreArchivo = ruta.lastIndexOf('/');

    return ruta.substring(0, posicionNombreArchivo) + environment.carpetaImagenGrande + ruta.substring(posicionNombreArchivo);
  }
}
