import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from 'src/app/itdear-core/message/services/message.service';
import { environment } from 'src/environments/environment';
import { tap, catchError, map } from 'rxjs/operators';
import { Parameters } from '../models/parameters';
import { User } from 'src/app/auth/models/user';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  private readonly USER = 'USER';

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    protected messageService: MessageService,
    protected authService: AuthService) {
  }


  get(urlApi: string): Observable<Parameters> {
    const url =`${urlApi}${environment.controller_Parametro}`;
    return this.http.get<Parameters>(url, this.httpOptions)
      .pipe(
        tap(result => this.loadParameters(result)),
        map(r => r),
        catchError(error => {
          return throwError(error);
        }));
  }

  loadParameters(parameters: Parameters) {
    const user = this.getUser();
    user.takeOrders = parameters.takeOrders;
    user.minValueOrder = parameters.minValueOrder;
    user.maxValueOrder = parameters.maxValueOrder;
    user.discountRate = parameters.discountRate;
    user.taxRate = parameters.taxRate;

    localStorage.setItem(this.USER, JSON.stringify(user));
  }

  getUser(): User {
    const user = localStorage.getItem(this.USER);
    return JSON.parse(user);
  }
}
