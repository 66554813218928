<div class="content-main m-10">
  <div class="d-flex form-section justify-content-end mt-1 px-3 py-1">
    <div>
      <button mat-raised-button color="warn" routerLink="" style="color: #ffffff;">Seguir comprando</button>
    </div>
  </div>
  <div class="d-flex form-section justify-content-between mt-1 p-3">
    <div>
      <h4 class="mb-0">Eventos<br><small>Consulta</small></h4>
    </div>
  </div>
  <div class="mat-elevation-z2 form-section mt-1 p-3">
    <div *ngIf="listaEventos && listaEventos.length > 0">
      <div class="row m-3">
        <div class="col-sm-12 col-md-6" style="text-align: center;">
          <img src="{{listaEventos[position].producto.urlImagenGrande}}" class="imagen-producto">
        </div>
        <div class="col-sm-12 col-md-6">
          <mat-card>
            <div class="d-flex justify-content-between mat-card-title">
              <div>Eventos</div>
              <div>
                <div class="text-muted"> {{ position+1}}/{{listaEventos.length}}</div>
              </div>
            </div>
            <mat-divider></mat-divider>
            <mat-card-content class="div-description">
              <br>
              <h5 class="m-0">{{listaEventos[position].producto.descLinea}}</h5>
              <p class="text-sublinea">{{listaEventos[position].producto.descSubLinea}}</p>
              <div style="min-height: 42px;">
                <h5 class="m-0" style="font-weight: 400;">{{listaEventos[position].producto.descProducto}}</h5>
                <div class="text-muted d-flex justify-content-between">
                  <small><small>COD</small> {{listaEventos[position].producto.idProducto}}</small>
                  <small>
                    <fa-icon matSuffix [icon]="['fas', 'barcode']">
                    </fa-icon> {{listaEventos[position].producto.ean}}
                  </small>
                </div>
              </div>
              <mat-divider></mat-divider>
              <h5 class="mt-3">
                {{listaEventos[position].descEvento}}
                <br>
                <small class="text-muted">
                  Evento valido hasta el
                  {{listaEventos[position].fechaFin | date: authService.getCompany().formatDate }}
                </small>
              </h5>
            </mat-card-content>
            <mat-card-actions *ngIf="listaEventos.length > 1">
              <div class="div-actions">
                <button mat-raised-button color="primary" (click)="previousImage()">
                  <fa-icon [icon]="['fas', 'angle-double-left']"></fa-icon> Anterior
                </button>
                <button mat-raised-button color="primary" (click)="nextImage()">
                  Siguiente <fa-icon [icon]="['fas', 'angle-double-right']"></fa-icon> </button>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
    <div *ngIf="!listaEventos || listaEventos.length === 0" class="alert alert-warning" role="alert">
      Sin eventos!
    </div>
  </div>
</div>
