import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changeForm: FormGroup;
  hideOldPassword = true;
  hidePassword = true;
  hideConfirmPassword = true;

  constructor(private authService: AuthService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private location: Location,
    private router: Router) { }

  ngOnInit(): void {
    this.changeForm = this.formBuilder.group({
      oldpassword: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmpassword: ['', [Validators.required]],
    });
  }

  changePassword() {
    this.authService.changePassword(this.changeForm.controls.oldpassword.value, this.changeForm.controls.password.value, this.changeForm.controls.confirmpassword.value)
      .subscribe(success => {
        if (success) {
          this.router.navigateByUrl('/Login');
          this.toastService.showSuccess('Cambio de contraseña', 'Se realizó el cambio de contraseña correctamente');
        }
      },
        err => {
          this.toastService.showError('Cambio de contraseña', err);
        });
  }

  back() {
    this.location.back();
  }
}
