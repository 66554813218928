import { Component, OnInit } from '@angular/core';
import { PedidoService } from '../../services/pedido.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { Pedido } from '../../models/pedido';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.scss']
})
export class PedidoComponent implements OnInit {

  pedido: Pedido;

  constructor(
    public service: PedidoService,
    public authService: AuthService,
    public routeNavigate: ActivatedRoute,
    private toastService: ToastService) {

  }

  ngOnInit(): void {
    if (this.routeNavigate.snapshot.url.filter(f => f.path === 't').length > 0) {
      const token = this.routeNavigate.snapshot.paramMap.get('token');
      const companyId = this.routeNavigate.snapshot.paramMap.get('companyId');
      this.loadByToken(token, companyId);
    } else {
      const idPedido = Number(
        this.routeNavigate.snapshot.paramMap.get('idPedido')
      );
      this.load(idPedido);
    }
  }


  load(idPedido: number) {
    this.service.get(idPedido)
      .subscribe(item => {
        this.pedido = item;
      });
  }

  loadByToken(token: string, companyId: string) {
    this.authService.urlApiCompany(companyId).subscribe(result => {
      this.service.getByToken(token, result.url)
        .subscribe(item => {
          this.pedido = item;
        });
    })
  }
}
