import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Observable, fromEvent } from 'rxjs';
import { map, shareReplay, mergeMap, takeUntil, debounceTime } from 'rxjs/operators';
import { AuthService } from './auth/services/auth.service';
import { version } from '../../package.json';
import { Router } from '@angular/router';
// import { CarritoService } from './commerce/services/carrito.service';
// import { Producto } from './commerce/models/producto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ECommerce';
  isExpanded = false;
  showSubmenu: boolean = false;
  showSubmenu1: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  version = version;
  // mobileQuery: MediaQueryList;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([
    Breakpoints.Small,
    Breakpoints.XSmall
  ])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );



  // private _mobileQueryListener: () => void;


  // constructor(
  //   changeDetectorRef: ChangeDetectorRef,
  //   media: MediaMatcher,
  //   public authService: AuthService) {
  //   this.mobileQuery = media.matchMedia('(max-width: 600px)');
  //   this._mobileQueryListener = () => changeDetectorRef.detectChanges();
  //   this.mobileQuery.addListener(this._mobileQueryListener);
  // }

  // ngOnDestroy(): void {
  //   this.mobileQuery.removeListener(this._mobileQueryListener);
  // }

  constructor(
    private breakpointObserver: BreakpointObserver,
    public authService: AuthService,
    // public carritoService: CarritoService,
    private router: Router) {
    // enter$.switchMap(_ => move$
    //     .debounceTime(2000)
    //     .takeUntil(leave$)
    //   )
    //   .subscribe(finalMouseMoveEventInSquare => { });
  }

  // public listaCarrito: Producto[] = [];

  ngOnInit() {
    const sidenavMain = document.querySelector('#sidenavMain');
    const move$ = fromEvent(sidenavMain, 'mousemove');
    const enter$ = fromEvent(sidenavMain, 'mouseenter');
    const leave$ = fromEvent(sidenavMain, 'mouseleave');

    enter$.pipe(
      mergeMap(_ => {
        return move$.pipe(
          debounceTime(300),
          // complete inner observable on mouseup event
          takeUntil(leave$)
        );
      })
    )
      .subscribe(result => {
        this.openMenu();
      });

    leave$.subscribe(_ => {
      if (!this.isExpanded) {
        this.isShowing = false;
      }
    });

    // if (this.authService.isLoggedIn()) {
    //   if (!this.carritoService.dataCar.getValue()) {
    //     this.carritoService.searchCar$.next();
    //   }
    // }

    // this.carritoService.dataCar$.subscribe((result: Producto[]) => {
    //   this.listaCarrito = result;
    // })
  }

  openMenu() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  closeMenu(close: boolean, snav: any) {
    snav.toggle()
    this.isExpanded = !close;
  }

  changePassword() {
    this.router.navigateByUrl('/ChangePassword');
  }

  logout() {
    this.authService.logout();
  }
}
