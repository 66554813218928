import { Injectable, DebugElement } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MessageService, MessageType } from 'src/app/itdear-core/message/services/message.service';
import { User } from '../models/user';
import { LoginResult } from '../models/login-result';
import { Company } from '../models/company';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly EXPIRATION_TOKEN = 'EXPIRATION_TOKEN';
  private readonly USER = 'USER';
  private readonly COMPANY = 'COMPANY';
  private readonly URL_API = 'URL_API';

  constructor(
    private http: HttpClient,
    private router: Router,
    protected messageService: MessageService) { }

  login(user: User): Observable<LoginResult> {
    return this.http.post<LoginResult>(`${environment.apiURLSEG}/${environment.controller_Session}/Login`, { userCust: user.userCust, userSuccli: user.userSuccli, password: user.password })
      .pipe(
        tap(result => this.doLoginUser(result)),
        map(r => r),
        catchError(error => {
          return throwError(error);
        }));
  }

  urlApiCompany(companyId: string): Observable<any> {
    const url = `${environment.apiURLSEG}/${environment.controller_Session}/UrlApi/${companyId}`;
    return this.http.get<string>(url)
      .pipe(
        map(r => r),
        catchError(error => {
          return throwError(error);
        }));
  }


  login2fa(user: User): Observable<LoginResult> {
    return this.http.post<LoginResult>(`${environment.apiURLSEG}/${environment.controller_Session}/Login/${user.code}`, { userCust: user.userCust, userSuccli: user.userSuccli, password: user.password})
      .pipe(
        tap(result => this.doLoginUser(result)),
        map(r => r),
        catchError(error => {
          return throwError(error);
        }));
  }

  reset(usercust: string, usersuccli: string, email: string): Observable<boolean> {
    return this.http.post<any>(`${environment.apiURLSEG}/${environment.controller_Session}/Reset`, { usercust, usersuccli, email })
      .pipe(
        tap(() => {
          this.messageService.log('Reset', 'AuthService', 'Reinicio contraseña', MessageType.Success);
        }
        ), mapTo(true),
        catchError(error => {
          return throwError(error);
        }));
  }

  changePassword(oldpassword: string, password: string, confirmpassword: string): Observable<boolean> {
    return this.http.put<any>(`${environment.apiURLSEG}/${environment.controller_Session}/ChangePassword`, { oldpassword, password, confirmpassword })
      .pipe(
        tap(() => {
          this.messageService.log('ChangePassword', 'AuthService', 'Cambio de contraseña', MessageType.Success);
        }
        ), mapTo(true),
        catchError(error => {
          return throwError(error);
        }));
  }

  assingPassword(usercust: string, usersuccli: string, email: string, token: string, password: string, confirmpassword: string): Observable<boolean> {
    return this.http.put<any>(`${environment.apiURLSEG}/${environment.controller_Session}/AssingPassword`, { usercust, usersuccli, email, token, password, confirmpassword })
      .pipe(
        tap(() => {
          this.messageService.log('AssingPassword', 'AuthService', 'Asignar contraseña', MessageType.Success);
        }
        ), mapTo(true),
        catchError(error => {
          return throwError(error);
        }));
  }

  logout() {
    this.doLogoutUser();
    return this.http.post<any>(`${environment.apiURLSEG}/${environment.controller_Session}/Logout`, {
      refreshToken: this.getRefreshToken()
    }).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        return throwError(error);
      }));
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  approvedTerms() {
    return this.getUser() && this.getUser().approvedTerms;
  }


  refreshToken() {
    const url = `${environment.apiURLSEG}/${environment.controller_Session}/${this.getRefreshToken()}`;
    return this.http.get<LoginResult>(url).pipe(
      tap(result => this.doLoginUser(result)),
      catchError(error => {
        this.removeTokens();
        return throwError(error);
      }));
  }

  getUser(): User {
    const user = localStorage.getItem(this.USER);
    return JSON.parse(user);
  }

  getCompany(): Company {
    const user = localStorage.getItem(this.COMPANY);
    return JSON.parse(user);
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  getUrlApi(): string {
    // return "https://localhost:44350/api/";
    return localStorage.getItem(this.URL_API);
  }

  private doLoginUser(loginResult: LoginResult) {
    if (loginResult.jwt) {
      this.storeTokens(loginResult);
    }
  }

  private doLogoutUser() {
    this.removeTokens();
    this.router.navigate(['/Login']);
  }

  public getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  // private storeJwtToken(jwt: string) {
  //   localStorage.setItem(this.JWT_TOKEN, jwt);
  // }

  private storeTokens(loginResult: LoginResult) {
    loginResult.company.formatDate = loginResult.company.formatDate.replace('mm', 'MM');
    loginResult.company.formatDate = loginResult.company.formatDate.replace('DD', 'dd');
    loginResult.company.formatDate = loginResult.company.formatDate.replace('YYYY', 'yyyy');
    localStorage.setItem(this.USER, JSON.stringify(loginResult.user));
    localStorage.setItem(this.COMPANY, JSON.stringify(loginResult.company));
    localStorage.setItem(this.JWT_TOKEN, JSON.parse(loginResult.jwt).token);
    localStorage.setItem(this.REFRESH_TOKEN, JSON.parse(loginResult.jwt).refreshToken);
    localStorage.setItem(this.EXPIRATION_TOKEN, JSON.parse(loginResult.jwt).expiration.toString());
    localStorage.setItem(this.URL_API, loginResult.company.urlApi.trim());
  }

  private removeTokens() {
    localStorage.removeItem(this.USER);
    localStorage.removeItem(this.COMPANY);
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.EXPIRATION_TOKEN);
    localStorage.removeItem(this.URL_API);
    this.router.navigate(['/Login']);
  }

  public aceptarTerminos() {
    const user = this.getUser();
    user.approvedTerms = true;
    localStorage.setItem(this.USER, JSON.stringify(user));
    this.router.navigate(['']);
  }
}
