<div class="bg">
  <form [formGroup]="changeForm">
    <mat-card class="mat-elevation-z6 form-signin px-0">
      <div>
        <mat-card-content class="px-5">
          <div class="title">
            <h4>Cambio de contraseña!</h4>
          </div>
          <mat-form-field>
            <input matInput placeholder="Ingrese su contraseña actual" formControlName="oldpassword"
              [type]="hideOldPassword ? 'password' : 'text'">
            <button type="button" mat-icon-button matSuffix (click)="hideOldPassword = !hideOldPassword"
              [attr.aria-label]="'Hide old password'" [attr.aria-pressed]="hideOldPassword">
              <fa-icon *ngIf="!hideOldPassword" [icon]="['fas', 'eye']"></fa-icon>
              <fa-icon *ngIf="hideOldPassword" [icon]="['fas', 'eye-slash']"></fa-icon>
            </button>
            <mat-error
              *ngIf="changeForm.controls['oldpassword'].errors?.required && (changeForm.controls['oldpassword'].dirty || changeForm.controls['oldpassword'].touched)">
              Requerido
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Ingrese su nueva contraseña" formControlName="password"
              [type]="hidePassword ? 'password' : 'text'">
            <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
              <fa-icon *ngIf="!hidePassword" [icon]="['fas', 'eye']"></fa-icon>
              <fa-icon *ngIf="hidePassword" [icon]="['fas', 'eye-slash']"></fa-icon>
            </button>
            <mat-error
              *ngIf="changeForm.controls['password'].errors?.required && (changeForm.controls['password'].dirty || changeForm.controls['password'].touched)">
              Requerido
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Confirme su nueva contraseña" formControlName="confirmpassword"
              [type]="hideConfirmPassword ? 'password' : 'text'">
            <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
              [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hideConfirmPassword">
              <fa-icon *ngIf="!hideConfirmPassword" [icon]="['fas', 'eye']"></fa-icon>
              <fa-icon *ngIf="hideConfirmPassword" [icon]="['fas', 'eye-slash']"></fa-icon>
            </button>
            <mat-error
              *ngIf="changeForm.controls['confirmpassword'].errors?.required && (changeForm.controls['confirmpassword'].dirty || changeForm.controls['confirmpassword'].touched)">
              Requerido
            </mat-error>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions class="px-5 mb-0">
          <button type="button" mat-raised-button color="primary" class="btn-block"
            [disabled]="changeForm.invalid" (click)="changePassword()">Cambiar contraseña</button>
        </mat-card-actions>
        <mat-card-actions class="px-5 pb-1">
          <button type="button" mat-raised-button color="accent" class="btn-block" (click)="back()"
            >Cancelar</button>
        </mat-card-actions>
      </div>
    </mat-card>
  </form>
</div>
