<div class="bg">
  <form [formGroup]="assingForm" (ngSubmit)="assingPassword()">
    <mat-card class="mat-elevation-z6 login p-0">
      <div class="div-logo">
        <a href="" matTooltip="Ir a inicio">
          <img class="logo" src="assets/images/ECommerce.png" alt="">
        </a>
      </div>
      <div class="form-signin">
        <mat-card-content>
          <div class="title">
            <h4>Asignar contraseña!</h4>
            <p>Ingrese el usuario, correo electrónico asociado a su cuenta y la nueva contraseña.</p>
          </div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Código de cliente" (ngModelChange)="replicateloginassing($event)" formControlName="usercust">
            <mat-error
              *ngIf="assingForm.controls['usercust'].errors?.required && (assingForm.controls['usercust'].dirty || assingForm.controls['usercust'].touched)">
              Requerido
            </mat-error>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Confirmar código de cliente" formControlName="usersuccli">
            <mat-error
              *ngIf="assingForm.controls['usersuccli'].errors?.required && (assingForm.controls['usersuccli'].dirty || assingForm.controls['usersuccli'].touched)">
              Requerido
            </mat-error>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Correo electrónico" formControlName="email"
              matTooltip="Confirmar correo electrónico registrado en el sistema">
            <mat-error
              *ngIf="assingForm.controls['email'].errors?.required && (assingForm.controls['email'].dirty || assingForm.controls['email'].touched)">
              Requerido
            </mat-error>
            <mat-error
              *ngIf="assingForm.controls['email'].errors?.email && (assingForm.controls['email'].dirty || assingForm.controls['email'].touched)">
              Formato de correo incorrecto
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Ingrese su contraseña" formControlName="password"
              [type]="hidePassword ? 'password' : 'text'">
            <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
              <fa-icon *ngIf="!hidePassword" [icon]="['fas', 'eye']"></fa-icon>
              <fa-icon *ngIf="hidePassword" [icon]="['fas', 'eye-slash']"></fa-icon>
            </button>
            <mat-error
              *ngIf="assingForm.controls['password'].errors?.required && (assingForm.controls['password'].dirty || assingForm.controls['password'].touched)">
              Requerido
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Confirme su contraseña" formControlName="confirmpassword"
              [type]="hideConfirmPassword ? 'password' : 'text'">
            <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
              [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hideConfirmPassword">
              <fa-icon *ngIf="!hideConfirmPassword" [icon]="['fas', 'eye']"></fa-icon>
              <fa-icon *ngIf="hideConfirmPassword" [icon]="['fas', 'eye-slash']"></fa-icon>
            </button>
            <mat-error
              *ngIf="assingForm.controls['confirmpassword'].errors?.required && (assingForm.controls['confirmpassword'].dirty || assingForm.controls['confirmpassword'].touched)">
              Requerido
            </mat-error>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions class="px-5 pb-5">
          <button type="submit" mat-raised-button color="primary" class="btn-block"
            [disabled]="assingForm.invalid">Asignar contraseña</button>
        </mat-card-actions>
      </div>
    </mat-card>
  </form>
</div>
