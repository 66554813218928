<div class="content-main" *ngIf="pedido">
  <div class="d-flex form-section justify-content-between mt-1 p-3">
    <div>
      <h4 class="mb-0">Pedido número
        {{pedido.idPedido}}<br><small>{{pedido.fecha | date: authService.getCompany() ? authService.getCompany().formatDate : pedido.formatDate}}</small>
      </h4>
    </div>
    <div *ngIf="authService.isLoggedIn()" class="espiga-menu">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/Pedido">Pedidos</a></li>
        <li class="breadcrumb-item active" aria-current="page">
          Consulta
        </li>
      </ol>
    </div>
  </div>
  <div class="mat-elevation-z1">
    <div class="row m-0">
      <div class="col-md-8 form-section mt-1 px-3">
        <ng-container *ngFor="let item of pedido.pedidoDetalle">
          <div class="row py-3">
            <div class="col-6">
              <div class="row">
                <div class="col-sm-12 col-md-4">
                  <img src="{{item.producto.urlImagen}}" height="100px"
                    onError="this.src='assets/images/not_found_product.jpg'">
                </div>
                <div class="col-sm-12 col-md-8">
                  <div class="w-100">{{item.producto.descProducto}}</div>
                  <div class="w-100">{{item.producto.descLinea}} - {{item.producto.descSubLinea}}
                  </div>
                  <small><small>COD</small> {{item.producto.idProducto}}</small><br>
                  <small>
                    <fa-icon matSuffix [icon]="['fas', 'barcode']">
                    </fa-icon> {{item.producto.ean}}
                  </small>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-md-12 col-lg-6">
                  <h5 style="text-align: end;">
                    {{item.cantidad | decimalCustomPipe : (authService.getCompany() ? authService.getCompany().formatNumber : pedido.formatNumber) : '' }}
                  </h5>

                </div>
                <div class="col-md-12 col-lg-6">
                  <div class="d-flex justify-content-end" matTooltip="Valor total">
                    <!-- <h2>Total</h2> -->
                    <h4>
                      {{item.subTotal | decimalCustomPipe : (authService.getCompany() ? authService.getCompany().formatNumber : pedido.formatNumber) : (authService.getCompany() ? authService.getCompany().formatCurrency : pedido.formatCurrency) }}
                    </h4>
                  </div>
                  <div class="d-flex justify-content-end text-muted" matTooltip="Valor unitario">
                    {{item.valorUnitario | decimalCustomPipe : (authService.getCompany() ? authService.getCompany().formatNumber : pedido.formatNumber) : (authService.getCompany() ? authService.getCompany().formatCurrency : pedido.formatCurrency) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-md-4 form-section mt-1 p-3" style="border-left: 5px solid #f5f5f5;">
        <h4>Resumen de la orden ({{pedido.pedidoDetalle.length}} artículos)</h4>
        <div>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Observaciones</mat-label>
            <textarea matInput [(ngModel)]="pedido.observaciones" disabled></textarea>
          </mat-form-field>
        </div>
        <div class="d-flex justify-content-between">
          <h6>Subtotal</h6>
          <h6>
            {{pedido.subTotal | decimalCustomPipe : (authService.getCompany() ? authService.getCompany().formatNumber : pedido.formatNumber) : (authService.getCompany() ? authService.getCompany().formatCurrency : pedido.formatCurrency) }}
          </h6>
        </div>
        <div class="d-flex justify-content-between">
          <h6>Descuento</h6>
          <h6>
            {{pedido.descuento | decimalCustomPipe : (authService.getCompany() ? authService.getCompany().formatNumber : pedido.formatNumber) : (authService.getCompany() ? authService.getCompany().formatCurrency : pedido.formatCurrency) }}
          </h6>
        </div>
        <div class="d-flex justify-content-between" *ngIf="pedido.iva > 0">
          <h6>Iva</h6>
          <h6>
            {{pedido.iva | decimalCustomPipe : (authService.getCompany() ? authService.getCompany().formatNumber : pedido.formatNumber) : (authService.getCompany() ? authService.getCompany().formatCurrency : pedido.formatCurrency) }}
          </h6>
        </div>
        <mat-divider class="my-2"></mat-divider>
        <div class="d-flex justify-content-between">
          <h5>Total</h5>
          <h5>
            {{pedido.total | decimalCustomPipe : (authService.getCompany() ? authService.getCompany().formatNumber : pedido.formatNumber) : (authService.getCompany() ? authService.getCompany().formatCurrency : pedido.formatCurrency) }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>
