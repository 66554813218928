import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Observable } from 'rxjs';
import { Cartera } from '../../models/cartera';
import { NgbdSortableHeader, SortEvent, SortDirection } from 'src/app/itdear-core/data-table/directives/sortable.directive';
import { CarteraService } from '../../services/cartera.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { AuthService } from 'src/app/auth/services/auth.service';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-cartera',
  templateUrl: './cartera.component.html',
  styleUrls: ['./cartera.component.scss']
})
export class CarteraComponent implements OnInit {

  listaCartera: Cartera[];
  listaCarteraFiltrada: Cartera[];

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public service: CarteraService,
    private toastService: ToastService,
    public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.service.getAll().subscribe(
      data => {
        this.listaCartera = data;
        this.listaCartera.sort();
        this.filtrar();
      }, error => {
        this.toastService.showError(`Consulta de cartera`, `Error: ${error}`);
      });
  }

  get totalCartera(): number {
    return this.listaCarteraFiltrada.reduce((sum, current) => sum + current.carSaldo, 0);
  }

  _filtro: string = '';

  get filtro(): string {
    return this._filtro;
  }

  set filtro(value: string) {
    this._filtro = value;
    this.filtrar();
  }

  filtrar() {
    if (this.listaCartera) {
      this.listaCarteraFiltrada = this.listaCartera.filter(f => this.aplicaFiltro(f));
    } else {
      this.listaCarteraFiltrada = [];
    }
  }

  _mesesCartera: number = 0;

  get mesesCartera(): number {
    return this._mesesCartera;
  }

  set mesesCartera(value: number) {
    this._mesesCartera = value;
    this.filtrar();
  }

  get fechaFiltroCartera(): Date {
    let fecha = moment();
    fecha = moment(fecha).add(this._mesesCartera * -1, 'months');
    return fecha.toDate();
  }

  aplicaFiltro(item: Cartera): boolean {
    if (this.mesesCartera > 0 && item.carFechaven <= this.fechaFiltroCartera) {
      return false;
    }

    if (this._filtro.trim() === '') {
      return true;
    }
    this._filtro.trim()
    const spl = this.removeAccents(this._filtro.toLowerCase()).split(' ');
    for (const f of spl) {
      if (!(this.removeAccents(item.carTipo.toLowerCase()).includes(f.trim()) || item.carNumero.toString().toLowerCase().includes(f.trim()) || this.removeAccents(item.carGuia.toLowerCase()).includes(f.trim()))) {
        return false;
      }
    }
    return true;
  }

  removeAccents(value: string) {
    return value
      .replace(/á/g, 'a')
      .replace(/é/g, 'e')
      .replace(/í/g, 'i')
      .replace(/ó/g, 'o')
      .replace(/ú/g, 'u');
  }

  // onSort({ column, direction }: SortEvent) {
  //   // resetting other headers
  //   this.headers.forEach(header => {
  //     if (header.sortable !== column) {
  //       header.direction = ``;
  //     }
  //   });
  //   this.sort(column, direction);
  // }

  // sort(column: string, direction: SortDirection) {
  //   console.log(`Ordenar por ${column}, dirección ${direction} `);
  // }

  sort(a: Cartera, b: Cartera) {
    if (a.diasVencimiento > b.diasVencimiento) {
      return 1;
    }
    if (a.diasVencimiento < b.diasVencimiento) {
      return -1;
    }
    return 0;
  }
}
