import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventoService } from '../../services/evento.service';
import { Evento } from '../../models/evento';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styleUrls: ['./evento.component.scss']
})
export class EventoComponent implements OnInit {

  listaEventos: Evento[];
  position: number = 0;

  constructor(
    public serviceEvento: EventoService,
    private toastService: ToastService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.serviceEvento.getAll().subscribe(
      data => {
        this.listaEventos = data;
      }, error => {
        this.toastService.showError(`Consulta de eventos`, `Error: ${error}`);
      });
  }

  previousImage() {
    if (this.position === 0) {
      this.position = this.listaEventos.length - 1;
    } else {
      this.position--;
    }
  }

  nextImage() {
    if (this.position >= this.listaEventos.length - 1) {
      this.position = 0;
    } else {
      this.position++;
    }
  }

  setIndexImage(position: number) {
    this.position = position;
  }
}
