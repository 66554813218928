import { Injectable } from '@angular/core';
import { Cartera } from '../models/cartera';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService, MessageType } from 'src/app/itdear-core/message/services/message.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CarteraService {

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    protected messageService: MessageService,
    protected toastService: ToastService,
    protected authService: AuthService) {
  }

  getAll(): Observable<Cartera[]> {
    const url = `${this.authService.getUrlApi()}${environment.controller_Cartera}`;
    return this.http.get<Cartera[]>(url, this.httpOptions).pipe(
      tap(_ =>
        this.messageService.log(
          'Cartera',
          'CarteraService',
          'Consultando cartera',
          MessageType.Success
        )
      ),
      map<Cartera[], Cartera[]>(items => {
        if (items) {
          return this.cargarCartera(items);
        } else {
          return null;
        }
      }),
      catchError(err => {
        this.messageService.handleError<Cartera[]>('Cartera', 'CarteraService', 'Consultando cartera');
        return throwError(err);
      })
    );
  }

  private cargarCartera(items: Cartera[]): Cartera[] {
    const listaCartera: Cartera[] = [];

    items.forEach(f => {
      const cartera = new Cartera();
      cartera.carCmpy = f.carCmpy;
      cartera.carCodusu = f.carCodusu;
      cartera.carTipo = f.carTipo;
      cartera.carNumero = f.carNumero;
      cartera.carCust = f.carCust;
      cartera.carSuccli = f.carSuccli;
      cartera.carGuia = f.carGuia;
      cartera.carFecha = new Date(f.carFecha);

      cartera.carAmt = f.carAmt;
      cartera.carSaldo = f.carSaldo;
      cartera.carNotacredito = f.carNotacredito;
      cartera.carAplicanc = f.carAplicanc;
      cartera.carFechaven = new Date(f.carFechaven);
      listaCartera.push(cartera);
    });

    return listaCartera;
  }
}
