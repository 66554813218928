import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface MessageLog {
  source: string;
  component: string;
  message: string;
  messageType: MessageType;
  date: Date;
}

export enum MessageType {
  Success = 1,
  Info,
  Warning,
  Danger
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  messages: MessageLog[] = [];

  add(message: MessageLog) {
    this.messages.push(message);
  }

  clear() {
    this.messages = [];
  }

  log(source: string, component: string, message: string, messageType: MessageType) {
    const msg: MessageLog = {
      source,
      component,
      message,
      messageType,
      date: new Date()
    };

    this.add(msg);
  }

  handleError<T>(source: string, component: string, message: string, result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(source, component, message, MessageType.Danger);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
