import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-assing-password',
  templateUrl: './assing-password.component.html',
  styleUrls: ['./assing-password.component.scss']
})
export class AssingPasswordComponent implements OnInit {
  assingForm: FormGroup;
  token: string;
  hidePassword = true;
  hideConfirmPassword = true;

  constructor(private authService: AuthService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.authService.logout();
    }

    this.token = this.route.snapshot.paramMap.get('token');

    this.assingForm = this.formBuilder.group({
      usercust: ['', Validators.required],
      usersuccli: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmpassword: ['', [Validators.required]],
    });
  }

  assingPassword() {
    this.authService.assingPassword(this.assingForm.controls.usercust.value, this.assingForm.controls.usersuccli.value, this.assingForm.controls.email.value, this.token, this.assingForm.controls.password.value, this.assingForm.controls.confirmpassword.value)
      .subscribe(success => {
        if (success) {
          this.router.navigateByUrl('/Login');
          this.toastService.showSuccess('Asignar contraseña', 'Se asignó la contraseña correctamente');
        }
      },
        err => {
          this.toastService.showError('Asignar contraseña', err);
        });
  }

  replicateloginassing(eventlogin) {
    this.assingForm.get('usersuccli').setValue(eventlogin);
  }
}
