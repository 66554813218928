import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MessageService, MessageType } from 'src/app/itdear-core/message/services/message.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { environment } from 'src/environments/environment';
import { Observable, throwError, BehaviorSubject, Subject } from 'rxjs';
import { tap, catchError, map, switchMap } from 'rxjs/operators';
import { Producto } from '../models/producto';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class CarritoService {

  public dataCar = new BehaviorSubject<Producto[]>(null);
  public dataCar$ = this.dataCar.asObservable();
  observaciones: string;

  public searchCar$ = new Subject();
  public updateCar$ = new Subject<Producto>();

  // public listaCarrito: Producto[] = [];

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private apiURL: string;

  constructor(
    private http: HttpClient,
    protected messageService: MessageService,
    protected toastService: ToastService,
    public authService: AuthService) {

    this.apiURL = `${authService.getUrlApi()}${environment.controller_Carro}`;

    this.init();

    this.updateCar$.pipe(
      switchMap((detalle) =>
        this.http.put(this.apiURL, detalle, this.httpOptions)
          .pipe(
            tap(_ => {
              this.messageService.log('AddItem', 'CarritoService', 'Agregando item al carrito', MessageType.Success);
            }
            ),
            catchError(err => {
              this.messageService.handleError('AddItem', 'CarritoService', 'Error agregando item al carrito');
              return throwError(err);
            })
          )
      ));
  }

  init() {
    this.searchCar$.pipe(
      switchMap(() => this.getAll()),
    ).subscribe((result: Producto[]) => {
      this.dataCar.next(result);
    },
      err => {
        this.toastService.showError('Error en la consulta del carrito', err);
        catchError(err);
      });

    this.updateCar$.pipe(
      switchMap((producto: Producto) => this.update(producto)),
    ).subscribe((result: Producto[]) => {
      // this.dataCar.next(result);
    },
      err => {
        this.toastService.showError('Error actualizando carrito', err);
        catchError(err);
      });
  }

  actualizarCarro(item: Producto) {
    let listaCarrito = this.dataCar.getValue();
    if (!listaCarrito) {
      listaCarrito = [];
    }

    const indice = listaCarrito.findIndex(f => f.idProducto === item.idProducto);

    if (indice !== -1) {
      listaCarrito[indice].cantidadSolicitada = item.cantidadSolicitada;
      if (listaCarrito[indice].cantidadSolicitada <= 0) {
        listaCarrito = listaCarrito.splice(indice, 1);
      }
    } else {
      if (item.cantidadSolicitada > 0) {
        listaCarrito.push(item);
      }
    }

    this.updateCar$.next(item);
  }

  getAll(): Observable<Producto[]> {
    const url = `${this.apiURL}`;
    return this.http.get<Producto[]>(url, this.httpOptions).pipe(
      tap(_ =>
        this.messageService.log(
          'GetAll',
          'CarritoService',
          'Consultando carrito',
          MessageType.Success
        )
      ),
      map<Producto[], Producto[]>(items => {
        if (items) {
          return this.cargarProductos(items);
        } else {
          return null;
        }
      }),
      catchError(err => {
        this.messageService.handleError<Producto[]>('Carro', 'CarroService', 'Consultando carrito de compras');
        return throwError(err);
      })
    );
  }


  update(producto: Producto): Observable<any> {
    const url = `${this.apiURL}`;
    return this.http.put(url, producto, this.httpOptions).pipe(
      tap(_ => this.messageService.log('AddItem', 'CarritoService', 'Agregando item al carrito', MessageType.Success)),
      catchError(err => {
        this.messageService.handleError('AddItem', 'CarritoService', 'Error agregando item al carrito');
        return throwError(err);
      })
    );
  }

  private cargarProductos(items: Producto[]): Producto[] {
    const listaProductos: Producto[] = [];

    items.forEach(f => {
      const producto = new Producto();
      producto.idProducto = f.idProducto;
      producto.descProducto = f.descProducto ?? '';
      producto.idLinea = f.idLinea;
      producto.descLinea = f.descLinea ?? '';
      producto.idSubLinea = f.idSubLinea;
      producto.descSubLinea = f.descSubLinea ?? '';
      producto.nuevo = f.nuevo;
      producto.urlImagen = f.urlImagen ?? '';
      producto.urlImagenGrande = this.rutaImagenGrande(f.urlImagen);
      producto.ean = f.ean ?? '';
      producto.precio = f.precio;
      producto.impuesto = f.impuesto;
      producto.porcDescuento = this.authService.getUser().discountRate;
      producto.porcIva = producto.aplicaIva ? this.authService.getUser().taxRate : 0;
      producto.cantidadMaxima = f.cantidadMaxima;
      producto.cantidadSolicitada = f.cantidadSolicitada;
      producto.unidadesEmpaque = f.unidadesEmpaque;

      listaProductos.push(producto);
    });

    return listaProductos;
  }

  private rutaImagenGrande(ruta: string): string {
    const posicionNombreArchivo = ruta.lastIndexOf('/');

    return ruta.substring(0, posicionNombreArchivo) + environment.carpetaImagenGrande + ruta.substring(posicionNombreArchivo);
  }
}
