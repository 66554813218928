<div class="content-main">
  <div class="d-flex form-section justify-content-between mt-1 p-3">
    <div>
      <h4 class="mb-0">Carrito de compras<br><small>Consulta</small></h4>
    </div>
  </div>
  <div class="mat-elevation-z1">
    <div class="row m-0">
      <div class="col-md-8 form-section mt-1 px-3">
        <ng-container *ngFor="let item of listaCarrito">
          <div class="row py-3">
            <div class="col-6">
              <div class="row">
                <div class="col-sm-12 col-md-4">
                  <img src="{{item.urlImagen}}" height="100px" onError="this.src='assets/images/not_found_product.jpg'">
                </div>
                <div class="col-sm-12 col-md-8">
                  <div *ngIf="item.evento" style="font-size: smaller;"
                    matTooltip="Evento valido hasta el {{ item.evento.fechaFin | date: authService.getCompany().formatDate}}">
                    <fa-icon class="icon-tag" matSuffix [icon]="['fas', 'tag']" size="lg">
                    </fa-icon> {{item.evento.descEvento}}
                  </div>
                  <div class="w-100">{{item.descProducto}}</div>
                  <div class="w-100">{{item.descLinea}} - {{item.descSubLinea}}
                  </div>
                  <small><small>COD</small> {{item.idProducto}}</small><br>
                  <small>
                    <fa-icon matSuffix [icon]="['fas', 'barcode']">
                    </fa-icon> {{item.ean}}
                  </small>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-md-12 col-lg-6">
                  <div class="ml-auto" style="max-width: 150px;">
                    <div>
                      <button *ngIf="item.cantidadSolicitada === 0" mat-raised-button class="btn-agregar w-100"
                        (click)="agregarCantidadProducto(item)">Agregar</button>
                      <div *ngIf="item.cantidadSolicitada > 0" class="input-group w-100">
                        <div class="input-group-prepend">
                          <button class="btn btn-agregar btn-sm" (click)="borrarCantidadProducto(item)">
                            <fa-icon matSuffix [icon]="['fas', 'trash-alt']">
                            </fa-icon>
                          </button>
                        </div>
                        <div class="input-group-prepend" *ngIf="item.cantidadSolicitada > 1">
                          <button class="btn btn-agregar btn-sm" (click)="removerCantidadProducto(item)">
                            <fa-icon matSuffix [icon]="['fas', 'minus']"></fa-icon>
                          </button>
                        </div>
                        <input type="text" class="form-control px-1 text-center" style="width: 50px;"
                          placeholder="Cantidad" aria-label="Cantidad" aria-describedby="btnCantidad"
                          [(ngModel)]="item.cantidadSolicitada" disabled>
                        <div class="input-group-append">
                          <button class="btn btn-agregar btn-sm" (click)="agregarCantidadProducto(item)">
                            <fa-icon matSuffix [icon]="['fas', 'plus']"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between" style="font-size: x-small;"
                      *ngIf="item.unidadesEmpaque > 1 && item.cantidadSolicitada > 0">
                      <div>
                        Número de cajas
                      </div>
                      <div>
                        {{item.cantidadSolicitada / item.unidadesEmpaque}}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between text-muted" style="font-size: x-small;">
                      <div>
                        Cant max {{item.cantidadMaxima}}
                      </div>
                      <div matTooltip="Unidades de empaque">
                        U Empaque {{item.unidadesEmpaque}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-6">
                  <div class="d-flex justify-content-end" matTooltip="Valor total">
                    <!-- <h2>Total</h2> -->
                    <h4>
                      {{item.total | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                    </h4>
                  </div>
                  <div class="d-flex justify-content-end text-muted" matTooltip="Valor unitario">
                    {{item.precio | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="listaCarrito" class="col-md-4 form-section mt-1 p-3" style="border-left: 5px solid #f5f5f5;">
        <h4>Resumen de la orden ({{listaCarrito.length}} artículos)</h4>
        <div>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Observaciones</mat-label>
            <textarea matInput [(ngModel)]="carritoService.observaciones"></textarea>
          </mat-form-field>
        </div>
        <div class="d-flex justify-content-between">
          <h6>Subtotal</h6>
          <h6>
            {{subtotal | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
          </h6>
        </div>
        <div class="d-flex justify-content-between">
          <h6>Descuento</h6>
          <h6>
            {{descuento | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
          </h6>
        </div>
        <div class="d-flex justify-content-between" *ngIf="this.authService.getUser().taxRate > 0">
          <h6>Iva</h6>
          <h6>
            {{iva | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
          </h6>
        </div>
        <mat-divider class="my-2"></mat-divider>
        <div class="d-flex justify-content-between">
          <h5>Total</h5>
          <h5>
            {{total | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
          </h5>
        </div>
        <div class="d-flex justify-content-between mt-5"
          [ngClass]="{'text-danger': authService.getUser().minValueOrder > totalAntesIva, 'text-muted': authService.getUser().minValueOrder <= totalAntesIva}">
          <!--<h6>Valor mínimo pedido <small>(Antes de IVA)</small></h6>-->
          <h6>Valor mínimo pedido </h6>
          <h6>
            {{authService.getUser().minValueOrder | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
          </h6>
        </div>
        <div class="d-flex justify-content-between"
          [ngClass]="{'text-danger': authService.getUser().maxValueOrder < total, 'text-muted': authService.getUser().maxValueOrder >= totalAntesIva}">
          <!--<h6>Valor máximo pedido <small>(IVA incluido)</small></h6>-->
          <h6>Valor máximo pedido</h6>
          <h6>
            {{authService.getUser().maxValueOrder | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
          </h6>
        </div>
        <div class="d-flex justify-content-between"
          [ngClass]="{'text-danger': authService.getUser().maxValueOrder < total, 'text-muted': authService.getUser().maxValueOrder >= totalAntesIva}">
          <h6>Valor disponible</h6>
          <h6>
            {{authService.getUser().maxValueOrder - total | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
          </h6>
        </div>
        <div class="w-100">
          <div class="alert alert-warning" role="alert" *ngIf="authService.getUser().minValueOrder > totalAntesIva">
            Necesita agregar
            {{authService.getUser().minValueOrder - totalAntesIva | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency}}
            en productos para completar el valor mínimo de pedido
          </div>
          <div class="alert alert-warning" role="alert" *ngIf="authService.getUser().maxValueOrder < total">
            Necesita eliminar
            {{ total - authService.getUser().maxValueOrder  | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency}}
            en productos para no sobrepasar el valor máximo de pedido
          </div>
          <button mat-raised-button color="warn" class="w-100 my-2" style="color: #ffffff;" routerLink="">Seguir
            comprando</button>
          <button mat-raised-button color="primary" class="w-100" (click)="confirmarPedido()"
            [disabled]="authService.getUser().maxValueOrder < total || authService.getUser().minValueOrder > totalAntesIva">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</div>
