<div class="bg">
  <form [formGroup]="resetForm" (ngSubmit)="reset()">
    <mat-card class="mat-elevation-z6 login p-0">
      <div class="div-logo">
        <a href="" matTooltip="Ir a inicio">
          <img class="logo" src="assets/images/ECommerce.png" alt="">
        </a>
      </div>
      <div class="form-signin">
        <mat-card-content>
          <div class="title">
            <h4>Recuperar el acceso a su cuenta!</h4>
            <p>Ingrese el usuario y correo electrónico asociado a su cuenta. Recibira un correo con un link que le
              permitira asignar una nueva contraseña.</p>
          </div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Código cliente" (ngModelChange)="replicateloginreset($event)" formControlName="usercust">
            <mat-error
              *ngIf="resetForm.controls['usercust'].errors?.required && (resetForm.controls['usercust'].dirty || resetForm.controls['usercust'].touched)">
              Requerido
            </mat-error>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Confirmar código cliente" formControlName="usersuccli">
            <mat-error
              *ngIf="resetForm.controls['usersuccli'].errors?.required && (resetForm.controls['usersuccli'].dirty || resetForm.controls['usersuccli'].touched)">
              Requerido
            </mat-error>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Correo electrónico" formControlName="email"
              matTooltip="Confirmar correo electrónico registrado en el sistema">
            <mat-error
              *ngIf="resetForm.controls['email'].errors?.required && (resetForm.controls['email'].dirty || resetForm.controls['email'].touched)">
              Requerido
            </mat-error>
            <mat-error
              *ngIf="resetForm.controls['email'].errors?.email && (resetForm.controls['email'].dirty || resetForm.controls['email'].touched)">
              Formato de correo incorrecto
            </mat-error>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
          <button type="submit" mat-raised-button color="primary" class="btn-block"
            [disabled]="resetForm.invalid">Recuperar contraseña</button>
        </mat-card-actions>
      </div>
    </mat-card>
  </form>
</div>
