import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({ name: 'decimalCustomPipe' })

export class DecimalCustomPipe implements PipeTransform {

  transform(value: number, format: string, prefix: string): string {
    format = format.trim();

    if (format.length > 1) {
      const decimalSeparator = format.substr(0, 1);
      const thousandSeparator = decimalSeparator === ',' ? '.' : ',';
      const length = format.length - 1;
      return this.format(value, decimalSeparator, thousandSeparator, length, length, prefix);
    } else {
      return this.format(value, environment.decimalSeparator, environment.thousandSeparator, environment.minFractionDigits, environment.maxFractionDigits, prefix);
    }
  }

  format(value: number, decimalSeparator: string, thousandSeparator: string, minFractionDigits: number, maxFractionDigits: number, prefix: string): string {
    if (!isNaN(Number(value))) {
      return  this.formatNumber(value, decimalSeparator, thousandSeparator, minFractionDigits, maxFractionDigits, prefix);
    }
    return `'${value}'`;
  }

  formatNumber(value: number, decimalSeparator: string, thousandSeparator: string, minFractionDigits: number, maxFractionDigits: number, prefix: string): string {
    const negative: boolean = value < 0;

    value = Math.abs(value);


    let positionDecimal = value.toString().lastIndexOf('.')

    if (positionDecimal < 0) {
      positionDecimal = value.toString().lastIndexOf(',')
    }

    let numberDecimalString: string = '';

    if (positionDecimal > 0) {
      numberDecimalString = value.toString().substr(positionDecimal + 1);
    }

    let numberInt: number;

    if (maxFractionDigits === 0) {
      numberInt = Math.round(value);
    } else {
      numberInt = Math.trunc(value);
    }

    const rest = numberInt.toString().length % 3;
    const thousands = numberInt.toString().substr(rest).match(/\d{3}/g);
    let result = numberInt.toString().substr(0, rest);

    if (thousands) {
      const separator = rest ? thousandSeparator : '';
      result += separator + thousands.join(thousandSeparator);
    }

    if (minFractionDigits === 0 || maxFractionDigits === 0) {
      result = result;
    } else {

      const ld = numberDecimalString.toString().length;

      if (ld > maxFractionDigits) {
        numberDecimalString = numberDecimalString.substring(0, maxFractionDigits);
      } else if (ld < minFractionDigits) {
        numberDecimalString += '0'.repeat(minFractionDigits - ld);
      }
      result = maxFractionDigits > 0 ? result + decimalSeparator + numberDecimalString : result;
    }

    result = !negative ? result : '-' + result;

    return prefix === undefined ? result : (result ? prefix + result : '');
  };

}
