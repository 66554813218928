<mat-nav-list>
  <mat-list-item title="Perfil" class="parent menu-level-1 my-2">
    <a (click)="openOption('/Usuario')">
      <fa-stack class="menu-module-i">
        <fa-icon [icon]="['fas', 'circle']" stackItemSize="2x"></fa-icon>
        <fa-icon [icon]="['fas', 'user']" [inverse]="true" stackItemSize="1x"></fa-icon>
      </fa-stack>
      <span class="menu-module-session">Perfil</span>
    </a>
  </mat-list-item>
  <mat-divider></mat-divider>
  <ng-container *ngFor="let menu of listaMenu" class="my-2">
    <mat-list-item title="{{ isExpanded || isShowing ? menu.subName : menu.name}}" class="parent menu-level-1">
      <fa-icon [icon]="menu.iconProp" class="menu-module-i" [fixedWidth]="true"></fa-icon>
      <span *ngIf="!menu.url" class="menu-module-s">{{menu.name}}</span>
      <a *ngIf="menu.url" title="{{ menu.subName }}" class="menu-level-2" mat-list-item (click)="openOption(menu.url)">
        {{ menu.name }}
      </a>
      <fa-icon *ngIf="menu.options && menu.options.length > 0" [icon]="['fas', 'angle-down']"
        [rotate]="menu.open ? 180 : 0" (click)="menu.open = !menu.open"></fa-icon>
    </mat-list-item>
    <div class="submenu" [ngClass]="{'expanded' : menu.open}" *ngIf="menu.open && (isShowing || isExpanded)">
      <ng-container *ngFor="let option of menu.options">
        <a title="{{ option.subName }}" class="menu-level-2" mat-list-item routerLink="{{ option.url }}">
          {{ option.name }}
        </a>
      </ng-container>
    </div>
  </ng-container>
  <mat-divider></mat-divider>
  <mat-list-item title="Perfil" class="parent menu-level-1 my-2" (click)="logout()">
    <fa-stack class="menu-module-i">
      <fa-icon [icon]="['fas', 'circle']" stackItemSize="2x"></fa-icon>
      <fa-icon [icon]="['fas', 'sign-out-alt']" [inverse]="true" stackItemSize="1x"></fa-icon>
    </fa-stack>
    <span class="menu-module-session">Salir</span>
  </mat-list-item>
</mat-nav-list>
