import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { LoadingScreenService } from '../services/loading-screen.service';


@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {
  /**
   * URLs for which the loading screen should not be enabled
   */
  //skippUrls = ['Session/Refresh', 'Menu', 'Parametro', 'Evento', 'Carro', 'Session/Login'];
  skippUrls = ['Session/Refresh', 'Menu', 'Parametro', 'Evento', 'Carro'];


  constructor(private loadinScreenService: LoadingScreenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let displayLoadingScreen = true;
    // console.log('Intercept loading screen:' + request.url);
    for (const skippUrl of this.skippUrls) {
      if (new RegExp(skippUrl).test(request.url)) {
        displayLoadingScreen = false;
        break;
      }
    }

    if (displayLoadingScreen) {
      // console.log(`ini displayLoadingScreen ${this.loadinScreenService.activeRequest} ${request.url}`);
      if (this.loadinScreenService.activeRequest === 0) {
        this.loadinScreenService.startLoading();
      }
      this.loadinScreenService.activeRequest++;
      // console.log('activeRequest ++ :' + this.loadinScreenService.activeRequest);
      return next.handle(request).pipe(
        catchError(err => {
          console.warn(`Intercept error: ${err}`);
          return throwError(err);

        }),
        finalize(() => {
          this.loadinScreenService.activeRequest--;
          // console.log(`finalize activeRequest -- ${this.loadinScreenService.activeRequest} ${request.url}`);
          if (this.loadinScreenService.activeRequest <= 0 || new RegExp('Session/Logout').test(request.url)) {
            this.loadinScreenService.stopLoading();
          }
          // if (this.loadinScreenService.activeRequest <= 0) {
          //  this.loadinScreenService.stopLoading();
          // }
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
