import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CustomErrorResponse } from '../models/custom-error-response';
import { environment } from 'src/environments/environment';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        // retry(1),
        catchError((error: HttpErrorResponse) => {
          const errorMessage: CustomErrorResponse = new CustomErrorResponse();

          if (error.error === null) {
            errorMessage.message = `Error Code: ${error.status}`;
            errorMessage.description = 'Error interno';
            errorMessage.detail = error.message;
            console.error('Error...', errorMessage);
          } else if ('CustomErrorWebApi' in error.error) {
            errorMessage.message = error.error.Message;
            errorMessage.description = error.error.Description;
            errorMessage.detail = error.error.Detail;
            errorMessage.isWarning = error.error.IsWarning;
            if (!errorMessage.isWarning) {
              console.error('Error...', errorMessage);
            }
            // else {
            //   console.warn('Warning...', errorMessage);
            // }
          } else if (error.error.constructor.name === 'ProgressEvent') {
            errorMessage.message = `Error Code: ${error.status}`;
            errorMessage.description = error.statusText;
            errorMessage.detail = error.message;
            console.error('Error...', errorMessage);
          } else {
            errorMessage.message = `Error Code: ${error.status}`;
            errorMessage.description = error.error.title;
            errorMessage.detail = error.message;
            console.error('Error...', errorMessage);
          }

          return throwError(errorMessage);
        })
      );
  }
}
