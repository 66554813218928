import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CarritoService } from '../../services/carrito.service';
import { Producto } from '../../models/producto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carro-boton',
  templateUrl: './carro-boton.component.html',
  styleUrls: ['./carro-boton.component.scss']
})
export class CarroBotonComponent implements OnInit {

  public listaCarrito: Producto[] = [];

  constructor(
    // public authService: AuthService,
    public carritoService: CarritoService,
    private router: Router) {

  }

  ngOnInit(): void {
    if (!this.carritoService.dataCar.getValue()) {
      this.carritoService.searchCar$.next();
    }

    this.carritoService.dataCar$.subscribe((result: Producto[]) => {
      this.listaCarrito = result;
    })
  }

  shoppingCart() {
    this.router.navigateByUrl('/Cart');
  }

}
