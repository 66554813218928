<div style="text-align: center;text-align: center;height: 40vh;font-size: 16px;">
  <div style="position: absolute;
  top: 30%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;">
    <fa-icon [icon]="['fas', 'ghost']" color="#0d60a2" size="10x"></fa-icon>
  </div>
</div>
<div style="text-align: center;text-align: center;">
  <div>
    <h3>¡Upsss!, creo que estas perdido <br><small>Intenta seleccionando una opción desde el menú o <a href="/Login">iniciando sesión</a> de nuevo</small></h3>
  </div>
</div>
