import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { TerminosService } from '../../services/terminos.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  viewMode = false;

  constructor(
    public authService: AuthService,
    private terminosService: TerminosService,
    protected toastService: ToastService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.viewMode = !!this.route.snapshot.url.find(f => f.path.toLowerCase() === `consulta`);
  }

  aceptar() {
    this.terminosService.aceptar()
      .subscribe(_ => this.authService.aceptarTerminos(),
        err => {
          this.toastService.showError('Aceptar terminos y condiciones', err);
        });
  }
}
