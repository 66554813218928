import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MessageService, MessageType } from 'src/app/itdear-core/message/services/message.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { Evento } from '../models/evento';
import { Observable, throwError, BehaviorSubject, Subject } from 'rxjs';
import { tap, map, catchError, switchMap } from 'rxjs/operators';
import { Producto } from '../models/producto';

@Injectable({
  providedIn: 'root'
})
export class EventoService {

  public data = new BehaviorSubject<Evento[]>(null);
  public data$ = this.data.asObservable();
  public search$ = new Subject();


  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    protected messageService: MessageService,
    protected toastService: ToastService,
    protected authService: AuthService) {

    this.search$.pipe(
      switchMap(() => this.getAll()),
    ).subscribe((result: Evento[]) => {
      this.data.next(result);
    },
      err => {
        this.toastService.showError('Error en la consulta de eventos', err);
        catchError(err);
      });
  }

  getAll(): Observable<Evento[]> {
    const url = `${this.authService.getUrlApi()}${environment.controller_Evento}`;
    return this.http.get<Evento[]>(url, this.httpOptions).pipe(
      tap(_ =>
        this.messageService.log(
          'Evento',
          'EventoService',
          'Consultando eventos',
          MessageType.Success
        )
      ),
      map<Evento[], Evento[]>(items => {
        if (items) {
          return this.cargarEventos(items);
        } else {
          return null;
        }
      }),
      catchError(err => {
        this.messageService.handleError<Evento[]>('Evento', 'EventoService', 'Consultando eventos');
        return throwError(err);
      })
    );
  }

  private cargarEventos(items: Evento[]): Evento[] {
    const listaEventos: Evento[] = [];

    items.forEach(f => {
      const evento = new Evento();
      evento.idTipo = f.idTipo;
      evento.fechaIni = new Date(f.fechaIni);
      evento.fechaFin = new Date(f.fechaFin);
      evento.descEvento = f.descEvento;
      evento.producto = new Producto();
      evento.producto.idProducto = f.producto.idProducto;
      evento.producto.descProducto = f.producto.descProducto;
      evento.producto.urlImagen = f.producto.urlImagen;
      evento.producto.urlImagenGrande = this.rutaImagenGrande(f.producto.urlImagen);
      evento.producto.idLinea = f.producto.idLinea;
      evento.producto.descLinea = f.producto.descLinea;
      evento.producto.idSubLinea = f.producto.idSubLinea;
      evento.producto.descSubLinea = f.producto.descSubLinea;
      evento.producto.ean = f.producto.ean;
      listaEventos.push(evento);
    });

    return listaEventos;
  }

  private rutaImagenGrande(ruta: string): string {
    const posicionNombreArchivo = ruta.lastIndexOf('/');

    return ruta.substring(0, posicionNombreArchivo) + environment.carpetaImagenGrande + ruta.substring(posicionNombreArchivo);
  }
}
