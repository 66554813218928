<div class="mat-typography">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div>
        <img class="img-producto" src="{{producto.urlImagenGrande}}"
          onError="this.src='assets/images/not_found_product.jpg'">
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <h2 class="m-0" style="font-weight: 400;">{{producto.descProducto}} </h2>
      <mat-divider></mat-divider>

      <h5 class="m-0">{{producto.descLinea}}</h5>
      <p class="text-sublinea">{{producto.descSubLinea}}</p>

      <div class="d-flex justify-content-between text-muted">
        <small><small>COD</small> {{producto.idProducto}}</small>
        <small><fa-icon matSuffix [icon]="['fas', 'barcode']">
        </fa-icon> {{producto.ean}}</small>
      </div>
      <div class="d-flex justify-content-between">
        <div>
          <div style="width: 130px;">
            <button *ngIf="producto.cantidadSolicitada === 0" mat-raised-button class="btn-agregar w-100"
              (click)="agregarCantidadProducto(producto)">Agregar</button>
            <div *ngIf="producto.cantidadSolicitada > 0" class="input-group w-100">
              <div class="input-group-prepend">
                <button class="btn btn-agregar btn-sm" (click)="borrarCantidadProducto(producto)">
                  <fa-icon matSuffix [icon]="['fas', 'trash-alt']">
                  </fa-icon>
                </button>
              </div>
              <div class="input-group-prepend" *ngIf="producto.cantidadSolicitada > 1">
                <button class="btn btn-agregar btn-sm" (click)="removerCantidadProducto(producto)">
                  <fa-icon matSuffix [icon]="['fas', 'minus']"></fa-icon>
                </button>
              </div>
              <input type="text" class="form-control px-1 text-center" style="width: 50px;" placeholder="Cantidad"
                aria-label="Cantidad" aria-describedby="btnCantidad" [(ngModel)]="producto.cantidadSolicitada" readonly>
              <div class="input-group-append">
                <button class="btn btn-agregar btn-sm" (click)="agregarCantidadProducto(producto)">
                  <fa-icon matSuffix [icon]="['fas', 'plus']"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between" style="font-size: x-small;" *ngIf="producto.unidadesEmpaque > 1 && producto.cantidadSolicitada > 0">
            <div>
              Número de cajas
            </div>
            <div>
              {{producto.cantidadSolicitada / producto.unidadesEmpaque}}
            </div>
          </div>
          <div class="d-flex justify-content-between text-muted" style="font-size: x-small;">
            <div>
              Cant max {{producto.cantidadMaxima}}
            </div>
            <div matTooltip="Unidades de empaque">
              U Empaque {{producto.unidadesEmpaque}}
            </div>
          </div>
          <div class="text-muted" style="font-size: x-small;" *ngIf="authService.getUser().maxValueOrder - totalPedido < 0">
            <div class="alert alert-warning p-0 m-0" role="alert">
              Necesita eliminar
              {{totalPedido - authService.getUser().maxValueOrder | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
              en productos para no sobrepasar el valor máximo de pedido
            </div>
          </div>
          <!-- <div class="text-muted" style="font-size: x-small;">
            Cant max {{producto.cantidadMaxima}}
          </div> -->
        </div>
        <div class="text-right">
          <b>{{producto.precio | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}</b>
          <!-- <p>
            {{producto.total | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
          </p> -->
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h4 class="mb-1">Productos relacionados {{productosRelacionados.length}}</h4>
      <mat-divider></mat-divider>
      <div class="carousel">
        <div class="content-carousel">
          <ul class="list-carousel">
            <li class="item-carousel" *ngFor="let item of productosRelacionados">
              <div class="m-1">
                <div (click)="verProducto(item)" class="div-img-relacionado cursor">
                  <img src="{{item.urlImagenGrande}}" class="img-relacionado"
                    onError="this.src='assets/images/not_found_product.jpg'">
                </div>
                <div style="background-color: white;">
                  <div>
                    <p class="precio-relacionado m-0">
                      {{item.precio | decimalCustomPipe : authService.getCompany().formatNumber : authService.getCompany().formatCurrency }}
                    </p>
                  </div>
                  <h5 class="m-0" style="font-weight: 400;">{{item.descProducto}} </h5>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
