import { Injectable } from '@angular/core';
import { ToastData } from '../models/toast-data';
import { environment } from 'src/environments/environment';
import { CustomErrorResponse } from '../../base/models/custom-error-response';
import { ToastType } from '../models/toast-type.enum';
import { timer, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: ToastData[] = [];
  deleteToast: Subject<ToastData> = new Subject();

  constructor() { }

  private show(type: ToastType, title: string, text: string, detail: string, buttonClose: boolean, closeTime: number) {
    const toast: ToastData = new ToastData();
    toast.title = title;
    toast.type = type;
    toast.text = text;
    toast.detail = detail;
    toast.buttonClose = buttonClose;
    toast.closeTime = closeTime;


    this.toasts.push(toast);

    const source = timer(closeTime * 1000);

    source.subscribe(_ => this.remove(toast));

  }


  showSuccess(title: string, message: string, closeTime: number = 0) {
    this.show(ToastType.success, title, message, null, true, closeTime === 0 ? environment.toastDelaySuccess : closeTime);
  }

  showInfo(title: string, message: string, closeTime: number = 0) {
    this.show(ToastType.info, title, message, null, true, closeTime === 0 ? environment.toastDelayInfo : closeTime);
  }

  showWarning(title: string, message: string, closeTime: number = 0) {
    this.show(ToastType.warning, title, message, null, true, closeTime === 0 ? environment.toastDelayWarning : closeTime);
  }

  showError(title: string, error: CustomErrorResponse | string, closeTime: number = 0) {
    if (typeof error === 'string') {
      this.show(ToastType.danger, title, error, null, true, closeTime === 0 ? environment.toastDelayError : closeTime);
    } else {
      const message = typeof error === 'string' ? error : `${error.message}. ${error.description}`;
      this.show(error.isWarning ? ToastType.warning : ToastType.danger, title, message, error.detail, true, error.isWarning ? closeTime === 0 ? environment.toastDelayWarning : closeTime : closeTime === 0 ? environment.toastDelayError : closeTime);
    }
  }

  remove(toast: ToastData) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

}
