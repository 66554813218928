<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <br>
  <p>
    <strong>¿{{data.question}}
      <span *ngIf="data.description" class="text-primary">"{{ data.description }}"</span>?</strong>
  </p>
  <p>
    <span color="warning" class="text-danger">{{ data.warning }}</span>
  </p>
  <br>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">{{ data.textButtonConfirm }}</button>
</mat-dialog-actions>
