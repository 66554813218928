import { Component, OnInit } from '@angular/core';
import { NovedadApp } from '../../models/novedad-app';
import { NovedadAppService } from '../../services/novedad-app.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';

@Component({
  selector: 'app-novedad',
  templateUrl: './novedad.component.html',
  styleUrls: ['./novedad.component.scss']
})
export class NovedadComponent implements OnInit {

  listaNovedades: NovedadApp[];
  position: number = 0;

  constructor(
    public service: NovedadAppService,
    private toastService: ToastService) { }

  ngOnInit(): void {
    this.service.getAll().subscribe(
      data => {

        this.listaNovedades = data;
      }, error => {
        this.toastService.showError(`Consulta de novedades de aplicación`, `Error: ${error}`);
      });
  }

  aceptar(novedad: NovedadApp) {
    this.service.aceptar(novedad.idNovedad)
      .subscribe(_ => novedad.aprobada = true,
        err => {
          this.toastService.showError('Aceptar novedad', err);
        });
  }

  previousImage(novedad: NovedadApp) {
    if (!novedad.aprobada) {
      this.aceptar(novedad);
    }
    if (this.position === 0) {
      this.position = this.listaNovedades.length - 1;
    } else {
      this.position--;
    }
  }

  nextImage(novedad: NovedadApp) {
    if (!novedad.aprobada) {
      this.aceptar(novedad);
    }
    if (this.position >= this.listaNovedades.length - 1) {
      this.position = 0;
    } else {
      this.position++;
    }
  }

  setIndexImage(position: number) {
    this.position = position;
  }
}
