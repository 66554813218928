import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})

export class ResetComponent implements OnInit {
  resetForm: FormGroup;
  // resetConfirmado: boolean;
  constructor(private authService: AuthService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private router: Router) { }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.authService.logout();
    }

    // this.resetConfirmado = false;

    this.resetForm = this.formBuilder.group({
      usercust: ['', Validators.required],
      usersuccli: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  reset() {
    this.authService.reset(this.resetForm.controls.usercust.value, this.resetForm.controls.usersuccli.value, this.resetForm.controls.email.value)
      .subscribe(success => {
        if (success) {
          // this.resetConfirmado = true;
          this.router.navigateByUrl('/Login');
          this.toastService.showSuccess('Recordar contraseña', 'Se envió un link al correo para la asignación de contraseña');
        }
      },
        err => {
          this.toastService.showError('Recordar contraseña', err);
        });
  }

  replicateloginreset(eventlogin) {
    this.resetForm.get('usersuccli').setValue(eventlogin);
  }

}
