import { Component, OnInit } from '@angular/core';
import { Evento } from '../../models/evento';
import { EventoService } from '../../services/evento.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-evento-boton',
  templateUrl: './evento-boton.component.html',
  styleUrls: ['./evento-boton.component.scss']
})
export class EventoBotonComponent implements OnInit {

  public listaEventos: Evento[] = [];

  constructor(
    public service: EventoService,
    private router: Router) {

  }

  ngOnInit(): void {
    if (!this.service.data.getValue()) {
      this.service.search$.next();
    }

    this.service.data$.subscribe((result: Evento[]) => {
      if (result) {
        this.listaEventos = result;
      } else {
        this.listaEventos = [];
      }
    })
  }

  open() {
    this.router.navigateByUrl('/Evento');
  }
}
