<div class="content-main m-10">
  <div class="d-flex form-section justify-content-end mt-1 px-3 py-1">
    <div>
      <button mat-raised-button color="warn" routerLink="" style="color: #ffffff;">Seguir comprando</button>
    </div>
  </div>
  <div class="d-flex form-section justify-content-between mt-1 p-3">
    <div>
      <h4 class="mb-0">Contactos<br><small>Consulta</small></h4>
    </div>
  </div>
  <div class="form-section row">
    <ng-container *ngIf="listaContactos && listaContactos.length > 0">
      <ng-container *ngFor="let item of listaContactos">
        <div class="col-md-6">
          <div class="card card-contacto">
            <div class="card-header">
              <h5> {{item.identificacion}}</h5>
            </div>
            <div class="card-body link-contacto">
              <h5 class="card-title">
                <fa-icon [icon]="['fas', 'user']" [fixedWidth]="true"></fa-icon> {{item.nombre}}
              </h5>
              <a href="mailto:{{item.correo}}">
                <h5 class="card-title">
                  <fa-icon [icon]="['fas', 'envelope']" [fixedWidth]="true"></fa-icon> {{item.correo}}
                </h5>
              </a>
              <a href="tel:{{item.telefono}}">
                <h5 class="card-title">
                  <fa-icon [icon]="['fas', 'phone']" [fixedWidth]="true" rotate="90"></fa-icon> {{item.telefono}}
                </h5>
              </a>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
