import { PedidoDetalle } from './pedido-detalle';

export class Pedido {
  idPedido: number;
  fecha: Date;
  marca: string;
  cerrado: boolean;
  porcDescuento: number;
  porcIva: number;
  pedidoDetalle: PedidoDetalle[];
  observaciones: string;
  totalAux: number;

  get subTotal(): number {
    return this.pedidoDetalle.reduce((sum, current) => sum + current.subTotal, 0);
  }

  get descuento(): number {
    return this.pedidoDetalle.reduce((sum, current) => sum + current.descuento, 0);
  }

  get totalAntesIva(): number {
    return this.pedidoDetalle.reduce((sum, current) => sum + current.totalAntesIva, 0);
  }

  get iva(): number {
    return this.pedidoDetalle.reduce((sum, current) => sum + current.iva, 0);
  }

  get total(): number {
    return this.pedidoDetalle.reduce((sum, current) => sum + current.total, 0);
  }

  verDetalle: boolean = false;
  formatDate: string;
  formatCurrency: string;
  formatNumber: string;

}
