import { Component, OnInit, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { Producto } from '../../models/producto';
import { ProductoService } from '../../services/producto.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { NgbdSortableHeader } from 'src/app/itdear-core/data-table/directives/sortable.directive';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Linea } from '../../models/linea';
import { LineaService } from '../../services/linea.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SubLinea } from '../../models/sublinea';
import { CarritoService } from '../../services/carrito.service';
import { MatDialog } from '@angular/material/dialog';
import { ProductoDetalleComponent } from '../producto-detalle/producto-detalle.component';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { EventoService } from '../../services/evento.service';
import { Evento } from '../../models/evento';
import { ProductoFiltroComponent } from '../producto-filtro/producto-filtro.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss']
})

export class ProductoComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  listaCarrito: Producto[];
  listaEvento: Evento[];

  // listaLineas: Linea[];

  permiteCrearPedidos: boolean;

  set pageEvent(value: PageEvent) {
    this.serviceProducto._pageEvent = value;
    this.paginar();
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;


  isHandset$: Observable<boolean> = this.breakpointObserver.observe([
    Breakpoints.Small,
    Breakpoints.XSmall
  ])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    public serviceProducto: ProductoService,
    public serviceLinea: LineaService,
    public serviceCarrito: CarritoService,
    public serviceEvento: EventoService,
    private toastService: ToastService,
    public authService: AuthService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
  }

  ngOnInit() {

    this.isHandset$.subscribe(result => {
      this.serviceProducto.modoLista = !result;
    });

    this.permiteCrearPedidos = this.authService.getUser().takeOrders;
    if (!this.permiteCrearPedidos) {
      return false;
    }

    const fecha = moment().add(-1, 'hours');
    if (!this.serviceProducto.productosCargados
      || this.serviceProducto.userCust !== this.authService.getUser().userCust || this.serviceProducto.userSuccli !== this.authService.getUser().userSuccli
      || this.serviceProducto.fechaConsulta < fecha.toDate()) {
      this.serviceProducto.getAll().subscribe(
        data => {
          this.serviceProducto.listaProducto = data;
          this.serviceProducto.productosCargados = true;
          this.serviceProducto.userCust = this.authService.getUser().userCust;
          this.serviceProducto.userSuccli = this.authService.getUser().userSuccli;
          this.serviceProducto.fechaConsulta = moment().toDate();
          this.actualizarCantidadesProducto();
          this.actualizarEventosProducto();
          this.filtro = this.serviceProducto.textoBusqueda;
          // this.filtrar();
          this.sort();
        }, error => {
          this.toastService.showError(`Consulta de productos`, `Error: ${error}`);
        });
    } else {
      this.actualizarCantidadesProducto();
      this.actualizarEventosProducto();
      this.paginator.length = this.serviceProducto.pageEvent.length;
      this.paginator.pageIndex = this.serviceProducto.pageEvent.pageIndex;
      this.paginator.pageSize = this.serviceProducto.pageEvent.pageSize;
      // this.sort();
    }

    if (!this.serviceProducto.lineasCargadas) {
      this.serviceLinea.getAll().subscribe(
        data => {
          this.serviceProducto.listaLineas = data;
          this.serviceProducto.lineasCargadas = true;
          this.sortLineas();
        }, error => {
          this.toastService.showError(`Consulta de líneas de producto`, `Error: ${error}`);
        });
    }

    if (!this.serviceCarrito.dataCar.getValue()) {
      this.serviceCarrito.searchCar$.next();
    }

    this.serviceCarrito.dataCar$.subscribe((result: Producto[]) => {
      this.listaCarrito = result;
      this.actualizarCantidadesProducto();
    });

    if (!this.serviceEvento.data.getValue()) {
      this.serviceEvento.search$.next();
    }

    this.serviceEvento.data$.subscribe((result: Evento[]) => {
      this.listaEvento = result;
      this.actualizarEventosProducto();
    });
  }

  actualizarCantidadesProducto() {
    if (this.serviceProducto.listaProducto && this.listaCarrito) {
      this.listaCarrito.forEach(fe => {
        const p = this.serviceProducto.listaProducto.find(f => f.idProducto === fe.idProducto);
        p.cantidadSolicitada = fe.cantidadSolicitada;
      });
    }
  }

  actualizarEventosProducto() {
    if (this.serviceProducto.listaProducto && this.listaEvento) {
      this.listaEvento.forEach(fe => {
        const p = this.serviceProducto.listaProducto.find(f => f.idProducto === fe.producto.idProducto);
        p.evento = fe;
      });
    }
  }

  sortLineas() {
    this.serviceProducto.listaLineas = this.serviceProducto.listaLineas.sort(this.ordenarLineas);

    this.serviceProducto.listaLineas.forEach(f => {
      f.subLineas = f.subLineas.sort(this.ordenarSubLineas);
    });
  }

  ordenarLineas(a: Linea, b: Linea) {
    if (a.descLinea > b.descLinea) {
      return 1;
    }
    if (a.descLinea < b.descLinea) {
      return -1;
    }
    return 0;
  }

  ordenarSubLineas(a: SubLinea, b: SubLinea) {
    if (a.descSubLinea > b.descSubLinea) {
      return 1;
    }
    if (a.descSubLinea < b.descSubLinea) {
      return -1;
    }
    return 0;
  }

  // _filtro: string = '';

  get filtro(): string {
    // return this._filtro;
    return this.serviceProducto.textoBusqueda;
  }

  set filtro(value: string) {
    // this._filtro = value;
    this.serviceProducto.textoBusqueda = value;
    this.filtrar(true);
  }

  paginar() {
    this.serviceProducto.listaProductoPaginada = this.serviceProducto.listaProductoFiltrada
      .slice(this.serviceProducto.pageEvent.pageIndex * this.serviceProducto.pageEvent.pageSize, (this.serviceProducto.pageEvent.pageIndex * this.serviceProducto.pageEvent.pageSize) + this.serviceProducto.pageEvent.pageSize);
  }

  filtrar(setFirtsPage: boolean) {
    if (this.serviceProducto.listaProducto) {
      // if (this._filtro.trim() === '' && this.lineasSeleccionadas.length === 0 && this.subLineasSeleccionadas.length === 0) {
      if (this.serviceProducto.textoBusqueda.trim() === '' && this.serviceProducto.lineasSeleccionadas.length === 0 && this.serviceProducto.subLineasSeleccionadas.length === 0) {
        this.serviceProducto.listaProductoFiltrada = this.serviceProducto.listaProducto;
      } else {
        this.serviceProducto.listaProductoFiltrada = this.serviceProducto.listaProducto.filter(f => this.aplicaFiltro(f));
      }
    } else {
      this.serviceProducto.listaProductoFiltrada = [];
    }
    this.serviceProducto.pageEvent.length = this.serviceProducto.listaProductoFiltrada.length;
    if (setFirtsPage) {
      this.paginator.firstPage();
    }
    this.paginar();
  }

  aplicaFiltro(item: Producto): boolean {

    if ((this.serviceProducto.lineasSeleccionadas.length > 0 && !(this.serviceProducto.lineasSeleccionadas.find(f => f.idLinea === item.idLinea)) ||
      (this.serviceProducto.subLineasSeleccionadas.length > 0 && !this.serviceProducto.subLineasSeleccionadas.find(f => f.idLinea === item.idLinea && f.idSubLinea === item.idSubLinea)))) {
      return false;
    }

    // const spl = this.removeAccents(this._filtro.toLowerCase()).split(' ');
    const spl = this.removeAccents(this.serviceProducto.textoBusqueda.toLowerCase()).split(' ');
    for (const f of spl) {
      if (!(this.removeAccents(item.idProducto.toLowerCase()).includes(f.trim())
        || this.removeAccents(item.descProducto.toLowerCase()).includes(f.trim())
        || this.removeAccents(item.descLinea.toLowerCase()).includes(f.trim())
        || this.removeAccents(item.descSubLinea.toLowerCase()).includes(f.trim())
        || item.ean.toLowerCase().includes(f.trim()))) {
        return false;
      }
    }
    return true;
  }

  removeAccents(value: string) {
    return value
      .replace(/á/g, 'a')
      .replace(/é/g, 'e')
      .replace(/í/g, 'i')
      .replace(/ó/g, 'o')
      .replace(/ú/g, 'u');
  }

  sort() {
    switch (this.serviceProducto.idOrdenamiento) {
      case 1: {
        this.serviceProducto.listaProducto = this.serviceProducto.listaProducto.sort(this.compararPrecioMenorAMayor);
        break;
      }
      case 2: {
        this.serviceProducto.listaProducto = this.serviceProducto.listaProducto.sort(this.compararPrecioMayorAMenor);
        break;
      }
      case 3: {
        this.serviceProducto.listaProducto = this.serviceProducto.listaProducto.sort(this.compararPorNombreProducto);
        this.serviceProducto.listaProducto = this.serviceProducto.listaProducto.sort(this.compararPorSubLinea);
        this.serviceProducto.listaProducto = this.serviceProducto.listaProducto.sort(this.compararPorLinea);
        break;
      }
      case 4: {
        this.serviceProducto.listaProducto = this.serviceProducto.listaProducto.sort(this.compararPorNombreProducto);
        this.serviceProducto.listaProducto = this.serviceProducto.listaProducto.sort(this.compararPorSubLinea);
        break;
      }
      case 5: {
        this.serviceProducto.listaProducto = this.serviceProducto.listaProducto.sort(this.compararPorNombreProducto);
        break;
      }
    }
    this.filtrar(false);
  }

  compararPrecioMenorAMayor(a: Producto, b: Producto) {
    if (a.precio > b.precio) {
      return 1;
    }
    if (a.precio < b.precio) {
      return -1;
    }
    return 0;
  }

  compararPrecioMayorAMenor(a: Producto, b: Producto) {
    if (a.precio < b.precio) {
      return 1;
    }
    if (a.precio > b.precio) {
      return -1;
    }
    return 0;
  }

  compararPorLinea(a: Producto, b: Producto) {
    if (a.descLinea > b.descLinea) {
      return 1;
    }
    if (a.descLinea < b.descLinea) {
      return -1;
    }
    return 0;
  }

  compararPorSubLinea(a: Producto, b: Producto) {
    if (a.descSubLinea > b.descSubLinea) {
      return 1;
    }
    if (a.descSubLinea < b.descSubLinea) {
      return -1;
    }
    return 0;
  }

  compararPorNombreProducto(a: Producto, b: Producto) {
    if (a.descProducto > b.descProducto) {
      return 1;
    }
    if (a.descProducto < b.descProducto) {
      return -1;
    }
    return 0;
  }

  OnChangeSelectAll($event: any) {
    if ($event.checked) {
      this.serviceProducto.listaLineas.forEach(f => f.seleccionado = true);
    } else {
      this.serviceProducto.listaLineas.forEach(f => f.seleccionado = false);
    }
  }

  lineaSeleccionada(linea: Linea) {
    if (linea.seleccionado) {
      if (!this.serviceProducto.lineasSeleccionadas.find(f => f.idLinea === linea.idLinea)) {
        this.serviceProducto.lineasSeleccionadas.push(linea);

        linea.subLineas.forEach(f => {
          f.seleccionado = false;
        });
        this.serviceProducto.subLineasSeleccionadas = this.serviceProducto.subLineasSeleccionadas.filter(f => f.idLinea !== linea.idLinea);
      }
    } else {
      this.serviceProducto.lineasSeleccionadas = this.serviceProducto.lineasSeleccionadas.filter(f => f !== linea);

      if (!linea.indeterminado) {
        linea.subLineas.forEach(f => {
          f.seleccionado = false;
        });
        this.serviceProducto.subLineasSeleccionadas = this.serviceProducto.subLineasSeleccionadas.filter(f => f.idLinea !== linea.idLinea);
      }
    }
    this.filtrar(true);
  }

  removerFiltroLinea(linea: Linea) {
    linea.seleccionado = false;
    this.lineaSeleccionada(linea);
  }

  subLineaSeleccionada(sublinea: SubLinea) {
    if (sublinea.seleccionado) {
      this.serviceProducto.subLineasSeleccionadas.push(sublinea);
    } else {
      this.serviceProducto.subLineasSeleccionadas = this.serviceProducto.subLineasSeleccionadas.filter(f => f !== sublinea);
    }

    const totalSubLineasSeleccionadas = sublinea.linea.subLineas.filter(f => f.seleccionado).length;
    if (totalSubLineasSeleccionadas === 0) {
      sublinea.linea.indeterminado = false;
      sublinea.linea.seleccionado = false;

    } else if (sublinea.linea.subLineas.length > totalSubLineasSeleccionadas) {
      sublinea.linea.seleccionado = false;
      sublinea.linea.indeterminado = true;
    } else if (sublinea.linea.subLineas.length === totalSubLineasSeleccionadas) {
      sublinea.linea.indeterminado = false;
      sublinea.linea.seleccionado = true;
    }

    if (!sublinea.linea.seleccionado) {
      this.removerFiltroLinea(sublinea.linea);
    } else {
      if (!this.serviceProducto.lineasSeleccionadas.find(f => f.idLinea === sublinea.linea.idLinea)) {
        this.serviceProducto.lineasSeleccionadas.push(sublinea.linea);
      }
    }

    this.filtrar(true);
  }

  removerFiltroSubLinea(subLinea: SubLinea) {
    subLinea.seleccionado = false;
    this.subLineaSeleccionada(subLinea);
  }

  agregarCantidadProducto(producto: Producto) {
    if (producto.cantidadSolicitada > producto.cantidadMaxima) {
      producto.cantidadSolicitada = producto.cantidadMaxima;
    } else {
      producto.cantidadSolicitada = producto.cantidadSolicitada + producto.unidadesEmpaque;
    }
    this.actualizarCarro(producto);
  }

  removerCantidadProducto(producto: Producto) {
    if (producto.cantidadSolicitada > 1) {
      producto.cantidadSolicitada = producto.cantidadSolicitada - producto.unidadesEmpaque
    } else {
      producto.cantidadSolicitada = 0;
    }
    this.actualizarCarro(producto);
  }

  borrarCantidadProducto(producto: Producto) {
    producto.cantidadSolicitada = 0;
    this.actualizarCarro(producto);
  }

  actualizarCarro(producto: Producto) {
    this.serviceCarrito.actualizarCarro(producto);

    if (this.totalPedido - this.authService.getUser().maxValueOrder > 0) {
      this.toastService.showWarning('Valor máximo pedido', `Necesita eliminar
        ${this.totalPedido - this.authService.getUser().maxValueOrder}
        en productos para no sobrepasar el valor máximo de pedido`, 2);
    }
  }

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];


  productosRelacionados(producto: Producto): Producto[] {
    return this.serviceProducto.listaProducto.filter(f => f.idLinea === producto.idLinea && f.idSubLinea === producto.idSubLinea);
  }

  verProducto(producto: Producto) {
    const dialogRef = this.dialog.open(ProductoDetalleComponent, {
      width: '80%', maxWidth: '700px'
    });
    dialogRef.componentInstance.producto = producto;
    dialogRef.componentInstance.productosRelacionados = this.productosRelacionados(producto);
  }

  get totalPedido(): number {

    let total = 0;

    if (this.serviceProducto.listaProducto) {
      total = this.serviceProducto.listaProducto.filter(f => f.cantidadSolicitada > 0).reduce((sum, current) => sum + current.total, 0);
    }

    return total;
  }


  abrirFiltro() {
    const dialogRef = this.dialog.open(ProductoFiltroComponent, {});
    dialogRef.componentInstance.serviceProducto = this.serviceProducto;
  }
}
