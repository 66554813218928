import { Component, OnInit, OnDestroy } from '@angular/core';
import { PromocionService } from '../../services/promocion.service';
import { Promocion } from '../../models/promocion';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';

@Component({
  selector: 'app-promocion',
  templateUrl: './promocion.component.html',
  styleUrls: ['./promocion.component.scss']
})
export class PromocionComponent implements OnInit, OnDestroy {

  listaPromociones: Promocion[];
  idInterval: any;
  position: number = 0;

  constructor(
    public servicePromocion: PromocionService,
    private toastService: ToastService) { }

  ngOnInit(): void {
    this.servicePromocion.getAll().subscribe(
      data => {
        this.listaPromociones = data;
      }, error => {
        this.toastService.showError(`Consulta de promociones`, `Error: ${error}`);
      });

    this.idInterval = setInterval(() => {
      this.nextImage();
    }, 5000);
  }

  previousImage() {
    if (this.position === 0) {
      this.position = this.listaPromociones.length - 1;
    } else {
      this.position--;
    }
  }

  nextImage() {
    if (this.position >= this.listaPromociones.length - 1) {
      this.position = 0;
    } else {
      this.position++;
    }
  }

  setIndexImage(position: number) {
    this.position = position;
  }

  ngOnDestroy() {
    if (this.idInterval) {
      clearInterval(this.idInterval);
    }
  }
}
