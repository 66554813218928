<div class="bg">
  <mat-card class="mat-elevation-z6 login p-0">
    <div class="div-logo">
      <a href="" matTooltip="Ir a inicio">
        <img class="logo" src="assets/images/ECommerce.png" alt="">
      </a>
    </div>
    <div *ngIf="!twoFactorAuth" [formGroup]="loginForm" class="form-signin">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Código de cliente" (ngModelChange)="replicatelogin($event)" formControlName="usercust">
          <mat-error
            *ngIf="loginForm.controls['usercust'].errors?.required && (loginForm.controls['usercust'].dirty || loginForm.controls['usercust'].touched)">
            Requerido
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Confirmar código de cliente" formControlName="usersuccli">
          <mat-error
            *ngIf="loginForm.controls['usersuccli'].errors?.required && (loginForm.controls['usersuccli'].dirty || loginForm.controls['usersuccli'].touched)">
            Requerido
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Clave de ingreso" formControlName="password" [type]="hide ? 'password' : 'text'">
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <fa-icon *ngIf="!hide" [icon]="['fas', 'eye']"></fa-icon>
            <fa-icon *ngIf="hide" [icon]="['fas', 'eye-slash']"></fa-icon>
          </button>
          <mat-error
            *ngIf="loginForm.controls['password'].errors?.required && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
            Requerido
          </mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button type="button" (click)="login()" mat-raised-button color="primary" class="btn-block"
          [disabled]="loginForm.invalid">Iniciar Sesión</button>
        <div class="w-100">
          <button type="button" class="btn btn-link" (click)="reset()">¿Olvidó su contraseña?</button>
        </div>
      </mat-card-actions>
    </div>



    <div *ngIf="twoFactorAuth" [formGroup]="loginCodeForm" class="form-signin">
      <mat-card-content>
        <div class="title">
          <h4>Código de verificación!</h4>
          <p>Se ha enviado un código de verificación a su correo, por favor ingreselo a continuación.</p>
        </div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Código verificación" formControlName="code">
          <mat-error
            *ngIf="loginCodeForm.controls['code'].errors?.required && (loginCodeForm.controls['code'].dirty || loginCodeForm.controls['code'].touched)">
            Requerido
          </mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button type="button" (click)="login2fa()" mat-raised-button color="primary" class="btn-block"
          [disabled]="loginCodeForm.invalid">Validar</button>
      </mat-card-actions>
    </div>
  </mat-card>

</div>
<footer class="footer">
  <h2 class="d-none d-sm-none d-md-block mt-2" style="color: #9e9e9e !important;">
    <!-- Recamier para iOS & Android -->
    <!-- <p class="text-muted m-0" style="font-size: initial;">Solución que nos ayuda a generar mayor conectividad con nuestros clientes, canales y empleados</p>-->
    <p class="text-muted m-0" style="font-size: initial;">MAVE</p>
    <p class="text-muted m-0" style="font-size: initial;">Version: 1.0.1.210716</p>
    <p class="text-muted m-0" style="font-size: initial;">© Copyright 2020 - 2021 Intechsys S.A.S. Todos los derechos reservados.</p>
    <p class="text-muted m-0" style="font-size: initial;">Descargar nuestra App desde las tiendas ahora!</p>
  </h2>
  <p class="d-block d-sm-block d-md-none text-muted m-0" style="font-size: initial;">Descargar nuestra App desde las tiendas ahora!</p>
  <div>
    <a mat-raised-button class="m-2" target="_blank" style="font-size: large;" href="{{urlAppStoreIOS}}"><fa-icon [icon]="['fab', 'apple']"></fa-icon> iPhone</a>
    <a mat-raised-button class="m-2" target="_blank" style="font-size: large;" href="{{urlAppStoreAndroid}}"><fa-icon [icon]="['fab', 'android']"></fa-icon> Android</a>
  </div>
</footer>
