import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MessageService, MessageType } from 'src/app/itdear-core/message/services/message.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { Observable, throwError } from 'rxjs';
import { Pedido } from '../models/pedido';
import { tap, catchError, map } from 'rxjs/operators';
import { PedidoDetalle } from '../models/pedido-detalle';
import { Producto } from '../models/producto';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    protected messageService: MessageService,
    protected toastService: ToastService,
    protected authService: AuthService) {
  }

  add(pedido: Pedido): Observable<number> {
    const url = `${this.authService.getUrlApi()}${environment.controller_Pedido}`;
    pedido.totalAux = pedido.total;

    return this.http.post(url, pedido, this.httpOptions)
      .pipe(
        tap((idPedido: number) => {
          this.messageService.log('Crear pedido', 'PedidoService', `Pedido creado con id ${idPedido}`, MessageType.Success);
        }
        ),
        catchError(err => {
          this.messageService.handleError('Crear pedido', 'PedidoService', 'Crear pedido');
          return throwError(err);
        })
      );
  }

  getAll(fechaIni: Date, fechaFin: Date): Observable<Pedido[]> {
    const url = `${this.authService.getUrlApi()}${environment.controller_Pedido}/${fechaIni.toISOString()}/${fechaFin.toISOString()}`;
    return this.http.get<Pedido[]>(url, this.httpOptions).pipe(
      tap(_ =>
        this.messageService.log(
          'Pedido',
          'PedidoService',
          'Consultando pedido por fechas',
          MessageType.Success
        )
      ),
      map<Pedido[], Pedido[]>(items => {
        if (items) {
          return this.cargarPedidos(items);
        } else {
          return null;
        }
      }),
      catchError(err => {
        this.messageService.handleError<Pedido[]>('Pedido', 'PedidoService', 'Consultando pedido');
        return throwError(err);
      })
    );
  }

  delete(idPedido: number): Observable<Pedido> {
    const url = `${this.authService.getUrlApi()}${environment.controller_Pedido}/${idPedido}`;
    return this.http.delete<Pedido>(url, this.httpOptions).pipe(
      tap(_ => this.messageService.log('Pedido', 'PedidoService', 'Eliminando pedido', MessageType.Success)),
      catchError(err => {
        this.messageService.handleError<Pedido>('Pedido', 'PedidoService', 'Eliminando pedido');
        return throwError(err);
      })
    );
  }

  get(idPedido: number): Observable<Pedido> {
    const url = `${this.authService.getUrlApi()}${environment.controller_Pedido}/${idPedido}`;
    return this.http.get<Pedido>(url, this.httpOptions).pipe(
      tap(_ =>
        this.messageService.log(
          'Pedido',
          'PedidoService',
          'Actualizado pedido',
          MessageType.Success
        )
      ),
      map<Pedido, Pedido>(item => this.cargarPedido(item)),
      catchError(err => {
        this.messageService.handleError<Pedido>('Pedido', 'PedidoService', 'Consultanto pedido');
        return throwError(err);
      })
    );
  }


  getByToken(token: string, urlApi: string): Observable<Pedido> {
    const url = `${urlApi}${environment.controller_Pedido}/GetByToken/${token}`;
    return this.http.get<Pedido>(url, this.httpOptions).pipe(
      tap(_ =>
        this.messageService.log(
          'Pedido',
          'PedidoService',
          'Actualizado pedido',
          MessageType.Success
        )
      ),
      map<Pedido, Pedido>(item => this.cargarPedido(item)),
      catchError(err => {
        this.messageService.handleError<Pedido>('Pedido', 'PedidoService', 'Consultanto pedido');
        return throwError(err);
      })
    );
  }

  private cargarPedidos(items: Pedido[]): Pedido[] {
    const listaPedidos: Pedido[] = [];

    items.forEach(f => {

      listaPedidos.push(this.cargarPedido(f));
    });

    return listaPedidos;
  }


  private cargarPedido(item: Pedido): Pedido {
    const pedido = new Pedido();
    pedido.idPedido = item.idPedido;
    pedido.fecha = item.fecha;
    pedido.marca = item.marca;
    pedido.porcDescuento = item.porcDescuento;
    pedido.porcIva = item.porcIva;
    pedido.cerrado = item.cerrado;
    pedido.observaciones = item.observaciones;
    pedido.formatCurrency = item.formatCurrency;
    pedido.formatDate = item.formatDate;

    if (pedido.formatDate) {
      pedido.formatDate = pedido.formatDate.replace('mm', 'MM');
      pedido.formatDate = pedido.formatDate.replace('DD', 'dd');
      pedido.formatDate = pedido.formatDate.replace('YYYY', 'yyyy');
    }

    pedido.formatNumber = item.formatNumber;
    pedido.pedidoDetalle = [];

    item.pedidoDetalle.forEach(
      d => {
        const detalle = new PedidoDetalle();
        detalle.cantidad = d.cantidad;
        detalle.valorUnitario = d.valorUnitario;
        detalle.aplicaIva = d.aplicaIva;
        detalle.porcDescuento = pedido.porcDescuento;
        detalle.porcIva = detalle.aplicaIva ? pedido.porcIva : 0;
        detalle.producto = new Producto();
        detalle.producto.idProducto = d.producto.idProducto;
        detalle.producto.descProducto = d.producto.descProducto ?? '';
        detalle.producto.idLinea = d.producto.idLinea;
        detalle.producto.descLinea = d.producto.descLinea ?? '';
        detalle.producto.idSubLinea = d.producto.idSubLinea;
        detalle.producto.descSubLinea = d.producto.descSubLinea ?? '';
        detalle.producto.nuevo = d.producto.nuevo;
        detalle.producto.urlImagen = d.producto.urlImagen ?? '';
        detalle.producto.urlImagenGrande = this.rutaImagenGrande(d.producto.urlImagen);
        detalle.producto.ean = d.producto.ean ?? '';
        detalle.producto.precio = d.producto.precio;
        detalle.producto.impuesto = d.producto.impuesto;
        detalle.producto.cantidadMaxima = d.producto.cantidadMaxima;
        detalle.producto.cantidadSolicitada = d.producto.cantidadSolicitada;
        pedido.pedidoDetalle.push(detalle);
      }
    );
    return pedido;
  }
  private rutaImagenGrande(ruta: string): string {
    const posicionNombreArchivo = ruta.lastIndexOf('/');

    return ruta.substring(0, posicionNombreArchivo) + environment.carpetaImagenGrande + ruta.substring(posicionNombreArchivo);
  }
}
