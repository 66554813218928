import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService, MessageType } from 'src/app/itdear-core/message/services/message.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { Promocion } from '../models/promocion';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PromocionService {

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    protected messageService: MessageService,
    protected toastService: ToastService,
    protected authService: AuthService) {
  }

  getAll(): Observable<Promocion[]> {
    const url = `${this.authService.getUrlApi()}${environment.controller_Promocion}`;
    return this.http.get<Promocion[]>(url, this.httpOptions).pipe(
      tap(_ =>
        this.messageService.log(
          'Promocion',
          'PromocionService',
          'Consultando promociones',
          MessageType.Success
        )
      ),
      map<Promocion[], Promocion[]>(items => {
        if (items) {
          return this.cargarPromociones(items);
        } else {
          return null;
        }
      }),
      catchError(err => {
        this.messageService.handleError<Promocion[]>('Promocion', 'PromocionService', 'Consultando promociones');
        return throwError(err);
      })
    );
  }

  private cargarPromociones(items: Promocion[]): Promocion[] {
    const listaPromociones: Promocion[] = [];

    items.forEach(f => {
      const promocion = new Promocion();
      promocion.fechaIni = new Date(f.fechaIni);
      promocion.fechaFin = new Date(f.fechaFin);
      promocion.urlBanner = f.urlBanner;
      promocion.descPromocion = f.descPromocion;
      listaPromociones.push(promocion);
    });

    return listaPromociones;
  }
}
