<div *ngIf="serviceProducto.listaLineas" class="col-2 mat-elevation-z2 form-section mt-1 p-0">
  <div>
    <mat-list role="list">
      <mat-list-item role="listitem" class="px-3">
        <h6>Líneas de producto</h6>
      </mat-list-item>
      <mat-divider></mat-divider>
      <div
        *ngIf="serviceProducto.lineasSeleccionadas.length > 0 || serviceProducto.subLineasSeleccionadas.length > 0"
        class="p-3">
        <mat-chip-list aria-label="Líneas seleccionadas">
          <mat-chip color="primary" class="mat-chip-custom" selected
            *ngFor="let linea of serviceProducto.lineasSeleccionadas" (removed)="removerFiltroLinea(linea)">
            {{linea.descLinea}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-chip-list aria-label="Sublíneas seleccionadas">
          <mat-chip color="accent" class="mat-chip-custom" selected
            *ngFor="let subLinea of serviceProducto.subLineasSeleccionadas"
            matTooltip="{{subLinea.descSubLinea}} - {{subLinea.linea.descLinea}}"
            (removed)="removerFiltroSubLinea(subLinea)">
            {{subLinea.descSubLinea}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <mat-divider *ngIf="serviceProducto.lineasSeleccionadas.length > 0"></mat-divider>
      <ng-container *ngFor="let linea of serviceProducto.listaLineas">
        <mat-list-item role="listitem" class="p-3 w-100">
          <mat-checkbox class="mr-auto" [checked]="linea.seleccionado" (change)="lineaSeleccionada(linea)"
            [(ngModel)]="linea.seleccionado" [(indeterminate)]="linea.indeterminado">{{linea.descLinea}}
          </mat-checkbox>
          <fa-icon *ngIf="!linea.verDetalle" [icon]="['fas', 'angle-down']"
            (click)="linea.verDetalle = !linea.verDetalle" matTooltip="Ver sublineas"></fa-icon>
          <fa-icon *ngIf="linea.verDetalle" [icon]="['fas', 'angle-up']"
            (click)="linea.verDetalle = !linea.verDetalle" matTooltip="Ocultar sublineas"></fa-icon>
        </mat-list-item>
        <ng-container *ngIf="linea.verDetalle">
          <mat-list-item role="listitem" *ngFor="let sublinea of linea.subLineas" class="ml-3">
            <mat-checkbox class="ml-3" [checked]="sublinea.seleccionado" (change)="subLineaSeleccionada(sublinea)"
              [(ngModel)]="sublinea.seleccionado">{{sublinea.descSubLinea}}</mat-checkbox>
          </mat-list-item>
        </ng-container>
      </ng-container>
    </mat-list>
  </div>
</div>
