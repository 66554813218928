import { SubLinea } from './sublinea';

export class Linea {
  idLinea: string;
  descLinea: string;
  subLineas: SubLinea[];
  seleccionado: boolean = false;
  verDetalle: boolean = false;
  indeterminado: boolean = false;
}
