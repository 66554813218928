import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {

  constructor(public authService: AuthService,
    private router: Router) { }

  ngOnInit(): void {
  }

  changePassword() {
    this.router.navigateByUrl('/ChangePassword');
  }

}
