import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService, MessageType } from 'src/app/itdear-core/message/services/message.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { Linea } from '../models/linea';
import { SubLinea } from '../models/sublinea';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LineaService {

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    protected messageService: MessageService,
    protected toastService: ToastService,
    protected authService: AuthService) {
  }

  getAll(): Observable<Linea[]> {
    const url = `${this.authService.getUrlApi()}${environment.controller_Linea}`;
    return this.http.get<Linea[]>(url, this.httpOptions).pipe(
      tap(_ =>
        this.messageService.log(
          'Linea',
          'LineaService',
          'Consultando líneas',
          MessageType.Success
        )
      ),
      map<Linea[], Linea[]>(items => {
        if (items) {
          return this.cargarLineas(items);
        } else {
          return null;
        }
      }),
      catchError(err => {
        this.messageService.handleError<Linea[]>('Linea', 'LineaService', 'Consultando líneas');
        return throwError(err);
      })
    );
  }

  private cargarLineas(items: Linea[]): Linea[] {
    const listaLineas: Linea[] = [];
    items.forEach(f => {

      const linea = new Linea();
      linea.idLinea = f.idLinea;
      linea.descLinea = f.descLinea ?? '';
      linea.subLineas = [];

      f.subLineas.forEach(fs => {
        const sublinea = new SubLinea();
        sublinea.linea = linea;
        sublinea.idLinea = fs.idLinea;
        sublinea.idSubLinea = fs.idSubLinea;
        sublinea.descSubLinea  = fs.descSubLinea;
        linea.subLineas.push(sublinea);
      });

      listaLineas.push(linea);
    });

    return listaLineas;
  }
}
