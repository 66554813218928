<div class="content-main m-10">
  <div class="d-flex form-section justify-content-end mt-1 px-3 py-1">
    <div>
      <button mat-raised-button color="warn" routerLink="" style="color: #ffffff;" >Seguir comprando</button>
    </div>
  </div>
  <div class="d-flex form-section justify-content-between mt-1 p-3">
    <div>
      <h4 class="mb-0">Usuario<br><small>Consulta</small></h4>
    </div>
  </div>
  <div class="mat-elevation-z2 form-section mt-1 p-3">
    <p class="font-weight-normal">
      <fa-icon [icon]="['fas', 'user']" [fixedWidth]="true"></fa-icon> {{authService.getUser().userName}}
    </p>
    <p class="font-weight-normal">
      <fa-icon [icon]="['fas', 'envelope']" [fixedWidth]="true"></fa-icon> {{authService.getUser().emailAddress}}
    </p>
    <p class="font-weight-normal">
      <fa-icon [icon]="['fas', 'phone']" [fixedWidth]="true" rotate="90"></fa-icon> {{authService.getUser().phone}}
    </p>
    <p class="font-weight-normal">
      <fa-icon [icon]="['fas', 'building']" [fixedWidth]="true"></fa-icon> {{authService.getUser().companyName}}
    </p>
    <div class="w-100 mt-5">
      <button mat-raised-button color="primary" (click)="changePassword()">Cambiar contraseña</button>
    </div>
  </div>
</div>
