<div class="content-main m-10">
  <div class="d-flex form-section justify-content-end mt-1 px-3 py-1" *ngIf="viewMode">
    <div>
      <button mat-raised-button color="warn" routerLink="" style="color: #ffffff;" >Seguir comprando</button>
    </div>
  </div>
  <div class="d-flex form-section justify-content-between mt-1 div-terms">
    <div>
      <h4 class="mb-0">Terminos y condiciones<br></h4>
    </div>
  </div>
  <div class="form-section mt-1 div-terms">
    {{authService.getCompany().terms}}
  </div>
  <div class="d-flex form-section justify-content-between mt-1 div-terms" *ngIf="!viewMode">
    <div>
      <button mat-raised-button color="primary" (click)="aceptar()">Aceptar terminos y condiciones</button>
    </div>
  </div>
</div>
