//  node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --configuration=prod --optimization=true --sourceMap=false

export const environment = {
  production: true,

  carpetaImagenGrande: '/big',

  decimalSeparator: '.',
  thousandSeparator: '.',
  minFractionDigits: 2,
  maxFractionDigits: 2,

  toastDelaySuccess: 5,
  toastDelayInfo: 8,
  toastDelayWarning: 5,
  toastDelayError: 30,
  app_type: '2',
  app_version: '1',

  urlAppStoreAndroid: '',
  urlAppStoreIOS: '',

  // API Produccion
  apiURLSEG: 'https://mave.com.co:8445/api',
  // Api Pruebas
  // apiURLSEG: 'http://mave.com.co:9090/api',

  // Autenticación

  controller_Session: 'Session',

  // App

  controller_ContactoEmpresa: 'ContactoEmpresa',
  controller_NovedadApp: 'NovedadApp',
  controller_Terminos: 'Terminos',

  // Cliente

  controller_Cartera: 'Cartera',
  controller_Menu: 'Menu',

  // Eccomerce

  controller_Carro: 'Carro',
  controller_Evento: 'Evento',
  controller_Linea: 'Linea',
  controller_Parametro: 'Parametro',
  controller_Pedido: 'Pedido',
  controller_Producto: 'Producto',
  controller_Promocion: 'Promocion',


};
