<div *ngIf="listaPromociones && listaPromociones.length > 0">
  <div>
    <div class="form-section banner" style="text-align: center;">
      <img src="{{listaPromociones[position].urlBanner}}">
      <a class="carousel-control-prev cursor" (click)="previousImage()" role="button">
        <!-- <span class="carousel-control-prev-icon" aria-hidden="true"></span> -->
        <fa-icon [icon]="['fas', 'angle-left']" size="2x"></fa-icon>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next cursor" (click)="nextImage()" role="button">
        <!-- <span class="carousel-control-next-icon" aria-hidden="true"></span> -->
        <fa-icon [icon]="['fas', 'angle-right']" size="2x"></fa-icon>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <div class="div-item-point">
      <a class="item-point" [ngClass]="{'item-point-selected': i === position}"
        *ngFor="let item of listaPromociones; let i = index" (click)="setIndexImage(i)"></a>
    </div>
  </div>
  <div class="form-section div-banner-text">
    {{listaPromociones[position].descPromocion}}
  </div>
</div>
