import { Injectable } from '@angular/core';
import { Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MessageService, MessageType } from '../../message/services/message.service';
import { ToastService } from '../../toast/services/toast.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { tap, map, catchError, switchMap } from 'rxjs/operators';
import { Menu } from '../models/menu';
import { environment } from 'src/environments/environment';
import { IconName } from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public data = new BehaviorSubject<Menu[]>(null);
  public data$ = this.data.asObservable();
  public search$ = new Subject();

  listaMenu: Menu[];

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient,
    protected messageService: MessageService,
    protected toastService: ToastService,
    protected authService: AuthService) {

    this.init();
  }


  init() {
    this.search$.pipe(
      switchMap(() => this.get()),
    ).subscribe((result: Menu[]) => {
      this.data.next(result);
    },
      err => {
        this.toastService.showError('Error en la consulta del menú', err);
        catchError(err);
      });
  }


  get(): Observable<Menu[]> {
    const url = `${this.authService.getUrlApi()}${environment.controller_Menu}`;

    return this.http.get<Menu[]>(url, this.httpOptions).pipe(
      tap(_ =>
        this.messageService.log(
          'Menu',
          'MenuService',
          'Consultando menú',
          MessageType.Success
        )
      ),
      map<Menu[], Menu[]>(items => {
        if (items) {
          return this.cargarMenu(items);
        } else {
          return null;
        }
      }),
      catchError(err => {
        this.messageService.handleError<Menu[]>('Menu', 'MenuService', 'Consultando menú');
        return throwError(err);
      })
    );
  }

  cargarMenu(menu: Menu[]): Menu[] {
    this.listaMenu = [];
    menu.forEach(f => {
      const item = new Menu();
      item.id = f.id;
      item.name = f.name;
      item.subName = f.subName;
      item.url = f.url;
      item.icon = f.icon;
      item.iconProp = ['fas', item.icon as IconName];
      this.listaMenu.push(item);
    });
    return this.listaMenu;
  }

}
