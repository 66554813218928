import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './components/menu/menu.component';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [MenuComponent],
  imports: [
    CommonModule,
    MatListModule,
    RouterModule,
    AppRoutingModule,
    FontAwesomeModule,
    MatTooltipModule
  ],
  exports: [
    MenuComponent
  ]
})
export class MenuModule { }
