import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MessageService, MessageType } from 'src/app/itdear-core/message/services/message.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { ContactoEmpresa } from '../models/contacto-empresa';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ContactoEmpresaService {

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    protected messageService: MessageService,
    protected toastService: ToastService,
    protected authService: AuthService) {
  }

  getAll(): Observable<ContactoEmpresa[]> {
    const url = `${environment.apiURLSEG}/${environment.controller_ContactoEmpresa}`;
    return this.http.get<ContactoEmpresa[]>(url, this.httpOptions).pipe(
      tap(_ =>
        this.messageService.log(
          'ContactoEmpresa',
          'ContactoEmpresaService',
          'Consultando contacto empresa',
          MessageType.Success
        )
      ),
      map<ContactoEmpresa[], ContactoEmpresa[]>(items => {
        if (items) {
          return this.cargarNovedades(items);
        } else {
          return null;
        }
      }),
      catchError(err => {
        this.messageService.handleError<ContactoEmpresa[]>('ContactoEmpresa', 'ContactoEmpresaService', 'Consultando contactos empresa');
        return throwError(err);
      })
    );
  }

  private cargarNovedades(items: ContactoEmpresa[]): ContactoEmpresa[] {
    const listaContactos: ContactoEmpresa[] = [];

    items.forEach(f => {
      const contacto = new ContactoEmpresa();
      contacto.nombre = f.nombre;
      contacto.correo = f.correo;
      contacto.telefono = f.telefono;
      contacto.identificacion = f.identificacion;
      listaContactos.push(contacto);
    });

    return listaContactos;
  }
}
