import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MessageService, MessageType } from 'src/app/itdear-core/message/services/message.service';
import { ToastService } from 'src/app/itdear-core/toast/services/toast.service';
import { Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { NovedadApp } from '../models/novedad-app';
import { tap, map, catchError, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NovedadAppService {

  public data = new BehaviorSubject<NovedadApp[]>(null);
  public data$ = this.data.asObservable();
  public search$ = new Subject();

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private apiURL: string;
  // private apiURL = environment.apiUrl_NovedadApp;

  constructor(
    private http: HttpClient,
    protected messageService: MessageService,
    protected toastService: ToastService,
    protected authService: AuthService) {

    this.apiURL = `${environment.apiURLSEG}${environment.controller_NovedadApp}`;

    this.search$.pipe(
      switchMap(() => this.getAll()),
    ).subscribe((result: NovedadApp[]) => {
      this.data.next(result);
    },
      err => {
        this.toastService.showError('Error en la consulta de novedades', err);
        catchError(err);
      });
  }

  getAll(): Observable<NovedadApp[]> {
    const url = `${this.apiURL}`;
    return this.http.get<NovedadApp[]>(url, this.httpOptions).pipe(
      tap(_ =>
        this.messageService.log(
          'NovedadApp',
          'NovedadAppService',
          'Consultando novedades aplicación',
          MessageType.Success
        )
      ),
      map<NovedadApp[], NovedadApp[]>(items => {
        if (items) {
          return this.cargarNovedades(items);
        } else {
          return null;
        }
      }),
      catchError(err => {
        this.messageService.handleError<NovedadApp[]>('NovedadApp', 'NovedadAppService', 'Consultando novedades aplicación');
        return throwError(err);
      })
    );
  }

  private cargarNovedades(items: NovedadApp[]): NovedadApp[] {
    const listaNovedades: NovedadApp[] = [];

    items.forEach(f => {
      const novedad = new NovedadApp();
      novedad.idNovedad = f.idNovedad;
      novedad.descNovedad = f.descNovedad;
      novedad.rutaImagen = f.rutaImagen;
      novedad.aprobada = f.aprobada;
      listaNovedades.push(novedad);
    });

    return listaNovedades;
  }

  aceptar(idNovedad: number): Observable<any> {
    return this.http.post(this.apiURL, idNovedad, this.httpOptions)
      .pipe(
        tap(_ => {
          const listaNovedades = this.data.getValue();
          const novedad = listaNovedades.find(f => f.idNovedad === idNovedad);
          novedad.aprobada = true;
          this.data.next(listaNovedades);

          this.messageService.log('Aceptar', 'NovedadService', 'Aceptando novedad', MessageType.Success);
        }
        ),
        catchError(err => {
          this.messageService.handleError('Aceptar', 'NovedadService', 'Aceptando novedad');
          return throwError(err);
        })
      );

  }
}
